
import __C from '@/primitives/_constant_'
import { mapState, mapGetters } from 'vuex'

export const CategoryMenuMixin = {
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, ['navDataitems']),
    ...mapGetters(__C.STORE_NAMESPACE.APPLICATION, ['categoryMenuPhase']),
  },
  data: () => ({
    menuItems: []
  }),
  created() {
    this.menuItems = [
      {
        pathPrefix: '/sysenv/config/code',
        children: [
          {
            name: 'System Group Code',
            path: '/sysenv/config/code/group',
            getChild: () => {
              this.hasChildSub = false
            }
          },
          {
            name: 'System Code',
            path: '/sysenv/config/code/index',
            getChild: () => {
              this.systemService.getCodeGroup(res => {
                res.forEach((c, i) => {
                  if (i === 0) {
                    let item = { name: 'All', code: 'ALL' }
                    this.currentMenu.sub.push(item)
                    this.codePropagated = item
                  }
                  let item = { name: c.name, code: c.code }
                  this.currentMenu.sub.push(item)
                })
              })
            }
          }
        ]
      },
      {
        pathPrefix: '/sysenv/config/nav',
        children: [
          {
            name: 'Menu Phase',
            path: '/sysenv/config/nav/phase',
            getChild: () => {
              this.hasChildSub = false
            }
          },
          {
            name: 'Menu Group',
            path: '/sysenv/config/nav/group',
            getChild: () => {
              this.categoryMenuPhase.forEach((p, i) => {
                if (i === 0) {
                  let item = { name: 'All', code: 'ALL' }
                  this.currentMenu.sub.push(item)
                  this.codePropagated = item
                }
                this.currentMenu.sub.push(p)
              })
            }
          },
          {
            name: 'Menu Item',
            path: '/sysenv/config/nav/item',
            getChild: () => {
              this.drawNavMenu = (_redraw = false) => {
                this.currentMenu.sub = []
                this.currentMenu.third = []
                this.menuTree = this.navDataitems
                this.menuTree.forEach((p, i) => {
                  let item = {
                    hasChild: true,
                    name: p.phaseName,
                    code: p.phaseCode,
                    getChild: () => {
                      p.children.forEach((g, i) => {
                        if (i === 0) {
                          let item = { name: 'All', code: 'ALL', parentName: p.phaseName, parentCode: p.phaseCode }
                          this.currentMenu.third.push(item)
                          if (!_redraw) this.codePropagated = item
                        }
                        let item = { name: g.groupName, code: g.groupCode, parentName: p.phaseName, parentCode: p.phaseCode }
                        this.currentMenu.third.push(item)
                      })
                    }
                  }
                  this.currentMenu.sub.push(item)
                  if (!_redraw && i === 0) this.setCode(item)
                })
              }
              this.drawNavMenu()
              this.hasChildThird = true
            }
          },
          {
            name: 'SubItem',
            path: '/sysenv/config/nav/subitem',
            getChild: () => {
              this.subItemEnabled = true
              this.drawNavMenu = (_redraw = false) => {
                this.currentMenu.sub = []
                this.currentMenu.third = []

                this.systemService.getNavMenuForSubItem(res => {
                  if (!res) res = []
                  this.menuTree = res
                  this.menuTree.forEach((p, pi) => {
                    let item = {
                      hasChild: true,
                      name: p.phaseName,
                      code: p.phaseCode,
                      getChild: () => {
                        p.children.forEach((g, gi) => {
                          g.children.forEach((t, ti) => {
                            if (gi === 0 && ti === 0) {
                              let item = {
                                name: 'All',
                                code: 'ALL',
                                groupName: 'All',
                                groupCode: 'ALL',
                                parentName: p.phaseName,
                                parentCode: p.phaseCode
                              }
                              this.currentMenu.third.push(item)
                              if (!_redraw) this.codePropagated = item
                            }
                            let item = {
                              name: g.groupName,
                              code: g.groupCode,
                              groupName: g.groupName,
                              groupCode: g.groupCode,
                              parentName: p.phaseName,
                              parentCode: p.phaseCode
                            }
                            this.currentMenu.third.push(item)
                          })
                        })
                      }
                    }
                    this.currentMenu.sub.push(item)
                    if (!_redraw && pi === 0) this.setCode(item)
                  })
                })
              }
              this.drawNavMenu()
              this.hasChildThird = true
            }
          }
        ]
      },
      {
        pathPrefix: '/sysenv/config/account',
        children: [
          {
            name: 'User',
            path: '/sysenv/config/account/user',
            getChild: () => {
              this.systemService.getCodeIndex('PERMISSION', res => {
                res.forEach((c, i) => {
                  if (i === 0) {
                    let item = (item = { name: 'All', code: 'ALL' })
                    this.currentMenu.sub.push(item)
                    this.codePropagated = item
                  }
                  let item = { name: c.name, code: c.code }
                  this.currentMenu.sub.push(item)
                })
              })
            }
          }
        ]
      },
      {
        pathPrefix: '/sysenv/config/acchistory',
        children: [
          {
            name: 'Overview',
            path: '/sysenv/config/acchistory/overview',
            getChild: () => {
              this.hasChildSub = false
            }
          },
        ]
      },
      {
        pathPrefix: '/sysenv/config/modcon',
        children: [
          {
            name: 'Writable Button',
            path: '/sysenv/config/modcon/wbtn',
            getChild: () => {
              this.subItemEnabled = true
              this.drawNavMenu = (_redraw = false) => {
                this.currentMenu.sub = []
                this.currentMenu.third = []

                this.systemService.getNavCatMenuNumChild('wbtn', res => {
                  if (!res) res = []
                  this.menuTree = res
                  this.menuTree.forEach((p, pi) => {
                    let hasChild = p.children.length > 0 ? true : false
                    let pretend = ['ALL'].includes(p.phaseCode) ? true : false

                    let item = {
                      hasChild,
                      pretend,
                      name: p.phaseName,
                      code: p.phaseCode,
                      getChild: () => {
                        let setPhaseDefault = () => {
                          let item = {
                            name: 'All',
                            code: 'ALL',
                            groupName: 'All',
                            groupCode: 'ALL',
                            parentName: p.phaseName,
                            parentCode: p.phaseCode
                          }
                          this.currentMenu.third.push(item)
                          if (!_redraw) this.codePropagated = item
                        }
                        if (pretend) setPhaseDefault()

                        let defaultInited = false

                        p.children.forEach((g, gi) => {
                          g.children.forEach((t, ti) => {
                            if ((gi === 0 && ti === 0) || (gi > 0 && ti === 0 && !defaultInited)) {
                              defaultInited = true
                              setPhaseDefault()
                            }

                            let item = {
                              name: g.groupName,
                              code: g.groupCode,
                              groupName: g.groupName,
                              groupCode: g.groupCode,
                              parentName: p.phaseName,
                              parentCode: p.phaseCode
                            }
                            this.currentMenu.third.push(item)
                          })
                        })
                      }
                    }
                    this.currentMenu.sub.push(item)
                    if (!_redraw && pi === 0) this.setCode(item)
                  })
                })
              }
              this.drawNavMenu()
              this.hasChildThird = true
            }
          }
        ]
      },
      {
        pathPrefix: '/sysenv/config/page',
        children: [
          {
            name: 'Dashboard',
            path: '/sysenv/config/page/dashboard',
            getChild: this.getChildNavControl
          },
          {
            name: 'Data-Table',
            path: '/sysenv/config/page/intdg',
            getChild: this.getChildNavControl
          },
          {
            name: 'Page-SVG',
            path: '/sysenv/config/page/svgpages',
            getChild: this.getChildNavControl
          },
          {
            name: 'Custom Component',
            path: '/sysenv/config/page/custom',
            getChild: this.getChildNavControl
          },
        ]
      },
      {
        pathPrefix: '/sysenv/config/chartlib',
        children: [
          {
            name: 'Customized',
            path: '/sysenv/config/chartlib/custom',
            getChild: this.getChildNavControl
          },
          {
            name: 'Donut',
            path: '/sysenv/config/chartlib/donut',
            getChild: this.getChildNavControl
          },
          {
            name: 'Pie',
            path: '/sysenv/config/chartlib/pie',
            getChild: this.getChildNavControl
          },
          {
            name: 'History',
            path: '/sysenv/config/chartlib/histogram',
            getChild: this.getChildNavControl
          },
          {
            name: 'Radar',
            path: '/sysenv/config/chartlib/radar',
            getChild: this.getChildNavControl
          },
          {
            name: 'Axis Bar',
            path: '/sysenv/config/chartlib/bar',
            getChild: this.getChildNavControl
          },
          {
            name: 'Progress Bar',
            path: '/sysenv/config/chartlib/barprogs',
            getChild: this.getChildNavControl
          },
          {
            name: 'Summary',
            path: '/sysenv/config/chartlib/summary',
            getChild: this.getChildNavControl
          },
          {
            name: 'Weekly',
            path: '/sysenv/config/chartlib/weekly',
            getChild: this.getChildNavControl
          },
          // {
          //   name: 'Solid Bar',
          //   path: '/sysenv/config/chartlib/barsolid',
          //   getChild: this.getChildNavControl
          // },
          {
            name: 'Summary Table',
            path: '/sysenv/config/chartlib/tsummary',
            getChild: this.getChildNavControl
          },
          // {
          //   name: 'Delta Bar',
          //   path: '/sysenv/config/chartlib/bardelta',
          //   getChild: this.getChildNavControl
          // },
          // {
          //   name: 'Skyline',
          //   path: '/sysenv/config/chartlib/skyline',
          //   getChild: this.getChildNavControl
          // },
          // {
          //   name: 'Floating Table',
          //   path: '/sysenv/config/chartlib/tfloat',
          //   getChild: () => {
          //     this.subItemEnabled = true
          //     this.drawNavMenu = (_redraw = false) => {
          //       this.currentMenu.sub = []
          //       this.currentMenu.third = []

          //       this.systemService.getNavCatMenuNumChild('tfloat', res => {
          //         if (!res) res = []
          //         this.menuTree = res
          //         this.menuTree.forEach((p, pi) => {
          //           let hasChild = p.children.length > 0 ? true : false
          //           let pretend = ['ALL'].includes(p.phaseCode) ? true : false

          //           let item = {
          //             hasChild,
          //             pretend,
          //             name: p.phaseName,
          //             code: p.phaseCode,
          //             getChild: () => {
          //               let setPhaseDefault = () => {
          //                 let item = {
          //                   name: 'All',
          //                   code: 'ALL',
          //                   groupName: 'All',
          //                   groupCode: 'ALL',
          //                   parentName: p.phaseName,
          //                   parentCode: p.phaseCode
          //                 }
          //                 this.currentMenu.third.push(item)
          //                 if (!_redraw) this.codePropagated = item
          //               }
          //               if (pretend) setPhaseDefault()

          //               let defaultInited = false

          //               p.children.forEach((g, gi) => {
          //                 g.children.forEach((t, ti) => {
          //                   if ((gi === 0 && ti === 0) || (gi > 0 && ti === 0 && !defaultInited)) {
          //                     defaultInited = true
          //                     setPhaseDefault()
          //                   }

          //                   let item = {
          //                     name: g.groupName,
          //                     code: g.groupCode,
          //                     groupName: g.groupName,
          //                     groupCode: g.groupCode,
          //                     parentName: p.phaseName,
          //                     parentCode: p.phaseCode
          //                   }
          //                   this.currentMenu.third.push(item)
          //                 })
          //               })
          //             }
          //           }
          //           this.currentMenu.sub.push(item)
          //           if (!_redraw && pi === 0) this.setCode(item)
          //         })
          //       })
          //     }
          //     this.drawNavMenu()
          //     this.hasChildThird = true
          //   }
          // },
        ]
      },
      {
        pathPrefix: '/sysenv/config/complibsvg',
        children: [
          {
            name: 'Tabs',
            path: '/sysenv/config/complibsvg/svgtabs',
            getChild: this.getChildNavControl
          },
          {
            name: 'Chart Controller',
            path: '/sysenv/config/complibsvg/chartcntl',
            getChild: () => {
              this.hasChildSub = false
            }
          },
          {
            name: 'Navigation',
            path: '/sysenv/config/complibsvg/svgnavs',
            getChild: () => {
              this.hasChildSub = false
            }
          },
          {
            name: 'SVG Control',
            path: '/sysenv/config/complibsvg/svgprops',
            getChild: this.getChildNavControl
          },
        ]
      },
      {
        pathPrefix: '/sysenv/config/lib',
        children: [
          {
            name: 'Icons',
            path: '/sysenv/config/lib/icons',
            getChild: () => {
              this.systemService.getCodeIndex('ICON', res => {
                res.forEach((c, i) => {
                  let item = { name: c.name, code: c.code }
                  this.currentMenu.sub.push(item)
                  if (i === 0) this.codePropagated = item
                })
              })
            }
          },
          {
            name: 'PDF Background',
            path: '/sysenv/config/lib/pdfbg',
            getChild: () => {}
          },
          {
            name: 'Color Scheme',
            path: '/sysenv/config/lib/colorscheme',
            getChild: () => {}
          },
          {
            name: 'Charts',
            path: '/sysenv/config/lib/charts',
            getChild: () => {
              this.systemService.getCodeIndex('CHART', res => {
                res.forEach((c, i) => {
                  let item = { name: c.name, code: c.code }
                  this.currentMenu.sub.push(item)
                  if (i === 0) this.codePropagated = item
                })
              })
            }
          },
        ]
      },
      {
        pathPrefix: '/sysenv/config/database',
        children: [
          {
            name: 'SQL Queries',
            path: '/sysenv/config/database/queries',
            getChild: this.getChildNavControl
          },
        ]
      },
      {
        pathPrefix: '/sysenv/config/files',
        children: [
          {
            name: 'Block Division',
            path: '/sysenv/config/files/blockdiv',
            getChild: () => {
              this.hasChildSub = false
            }
          },
        ]
      },
    ]
  },
  methods: {
    getChildNavControl(path) {

      // /sysenv/config/page/intdg
      // /sysenv/config/page/dashboard
      // /sysenv/config/page/svgpages

      // /sysenv/config/chartlib/donut
      // /sysenv/config/chartlib/pie
      // /sysenv/config/chartlib/bar
      // /sysenv/config/chartlib/barsolid
      // /sysenv/config/chartlib/barprogs
      // /sysenv/config/chartlib/histogram
      // /sysenv/config/chartlib/skyline
      // /sysenv/config/chartlib/tsummary

      let pathParts = path ? path.split('/'): []
      let chartname = pathParts.length > 0 ? pathParts[pathParts.length - 1] : ''

      this.subItemEnabled = true
      this.drawNavMenu = (_redraw = false) => {
        this.currentMenu.sub = []
        this.currentMenu.third = []

        this.systemService.getNavCatMenuNumChild(chartname, res => {
          if (!res) res = []
          this.menuTree = res
          this.menuTree.forEach((p, pi) => {
            let hasChild = p.children.length > 0 ? true : false
            let pretend = ['ALL'].includes(p.phaseCode) ? true : false

            let item = {
              hasChild,
              pretend,
              name: p.phaseName,
              code: p.phaseCode,
              getChild: () => {
                let setPhaseDefault = () => {
                  let item = {
                    name: 'All',
                    code: 'ALL',
                    groupName: 'All',
                    groupCode: 'ALL',
                    parentName: p.phaseName,
                    parentCode: p.phaseCode
                  }
                  this.currentMenu.third.push(item)
                  if (!_redraw) this.codePropagated = item
                }
                if (pretend) setPhaseDefault()

                let defaultInited = false

                p.children.forEach((g, gi) => {
                  g.children.forEach((t, ti) => {
                    if ((gi === 0 && ti === 0) || (gi > 0 && ti === 0 && !defaultInited)) {
                      defaultInited = true
                      setPhaseDefault()
                    }

                    let item = {
                      name: g.groupName,
                      code: g.groupCode,
                      groupName: g.groupName,
                      groupCode: g.groupCode,
                      parentName: p.phaseName,
                      parentCode: p.phaseCode
                    }
                    this.currentMenu.third.push(item)
                  })
                })
              }
            }
            this.currentMenu.sub.push(item)
            if (!_redraw && pi === 0) this.setCode(item)
          })
        })
      }
      this.drawNavMenu()
      this.hasChildThird = true
    }
  }
}
