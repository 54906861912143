import * as d3 from 'd3'

export default {
  
  methods: {
    draw_Model_Equipment(_d) {

      this.model_Equipment = this.svg
      .append('g')
      .attr('id', `MODEL_EQUIPMENT`)
      .attr('transform', `translate(0,0)`)

      // Load a Legend SVG
      this.loadSvg(
        this.model_Equipment, {
        x: 0, y: 0,
        url : `/Visualization/Layout_Equipment/SVGs/${_d.MOD}_${_d.LEV}.svg`
      }).then(() => {

        d3.selectAll('text').style('font-family', 'roboto')
        this.eqList.filter(f=> f.MOD == _d.MOD && f.LEV == _d.LEV).forEach(d=>{
          d3.select(`#NO-${d.ID}`)
          .attr('cursor', 'pointer').call(this.call_Equipment, d)
          d3.select(`#C-${d.ID}`)
          .style('fill', ()=> {
            let color = '#fff'
            if(d.PO_DATE) color = '#85C7E5'
            if(d.FAT_ACT) color = '#F7BACE'
            if(d.INSTALL) color = '#8DBB3F'
            return color
          })
          if(d.ETA_SRD < 0) {
            let txt = d3.select(`#NO-${d.ID}`).selectAll('text').nodes()[1]
            d3.select(txt).style('fill', 'red')
          }
          // console.log(_d)
        })
        
        this.draw_Equipment_Canvas(_d) // ----->> canvas_Overall.js
      }) 
    
    },
    

    draw_Equipment_Canvas(_d) {
      this.canvas_Equipment = this.svg
      .append('g')
      .attr('id', `CANVAS_EQUIPMENT`)
      .attr('transform', `translate(0,0)`)

      this.gmx_btn_Navigation(
        this.canvas_Equipment, 
        {type: 'home', x: 450, y: 83, scale: 0.8,}, 
        {
          remove: [this.model_Equipment, this.canvas_Equipment],
          dur: 500, 
          timeout: 1000,
          exeFunc: this.draw_Model_Overall,
          data: _d
        }
      )

      this.gmx_btn_Navigation(
        this.canvas_Equipment, 
        {type: 'back', x: 490, y: 83, scale: 0.8,}, 
        {
          remove: [this.model_Equipment, this.canvas_Equipment],
          dur: 500, 
          timeout: 1000,
          exeFunc: this.draw_Model_Module,
          data: _d
        }
      )

      d3.select(`#TITLE_MAIN`).text(`${_d.MOD} MODULE -`)
      d3.select(`#TITLE_SUB`).attr('x', this.getNodeElValue('#TITLE_MAIN','width') + 35).text(_d.NAME)

      this.bar_Summary(this.canvas_Equipment, this.svgFile.find(f=> f.MOD == _d.MOD && f.LEV == _d.LEV), {idx: 'OV', x: 90,   y: 280, bar: 250})

      this.draw_detail()

      // ---------------------------------------- Search ----------------------------------------
      this.gmx_btn_Toggle(
        this.canvas_Equipment, 
        {
          variable:   'Toggle', // variable for the value of toggle status
          id:         'TOG',
          x:          540,
          y:          97,
          width:      55,
          height:     18,
          title:       'Tag/Name',
          cColor:     '#fff',

          on: {
            value:    'Tag #',
            bColor:   '#44A9DF',
            tColor:   '#fff',
          },
          off: {
            value:    'Name',
            bColor:   '#D92129',
            tColor:   '#fff',
          }      
        },
        this.toggle_action
      )

      // ---------------------------------------- Search ----------------------------------------
      this.search.mod = _d.MOD
      this.search.lev = _d.LEV
      let data__ = {
        x         : 40,
        y         : 240,
        width     : 200,
        height    : 60.5,
        label     : 'Search',
        labelWidth: 40,
        targets: [
          { text: 'TAG No / ' },
          { text: 'Description'},
        ],
        callFunc: this.call_Search// --->  in Event.js
      }
      this.drawSearchToolFree(this.canvas_Equipment, data__) //---> Global function

    },

    
  }
}