import * as d3 from 'd3'

export default {
  data: () => ({
  
    columns: [
      {title: '',           item: 'Phase',   group: 'Y', code: 'PHASE',   width: 85, align: 'start',},
      {title: '',           item: 'Group',   group: 'Y', code: 'GROUP',   width: 90, align: 'start',},
      {title: '',           item: 'Subcont', group: 'Y', code: 'SUBCONT', width: 75, align: 'start',},
      {title: '',           item: 'Total',   group: '',  code: 'TOTAL',   width: 50, align: 'end',  },
      {title: '',           item: 'Plan',    group: '',  code: 'P_WK',    width: 45, align: 'end',  },
      {title: 'This Week',  item: 'Actual',  group: '',  code: 'A_WK',    width: 45, align: 'end',  },
      {title: '',           item: 'Delta',   group: '',  code: 'D_WK',    width: 40, align: 'end',  },
      {title: '',           item: 'Plan',    group: '',  code: 'P_CUM',   width: 45, align: 'end',  },
      {title: 'Cumulative', item: 'Actual',  group: '',  code: 'A_CUM',   width: 45, align: 'end',  },
      {title: '',           item: 'Delta',   group: '',  code: 'D_CUM',   width: 40, align: 'end',  },
    ],

    table: {
      dataSet:    [],
      width:      0,
      padding:    5,
      lineHeight: 18,
      gX1:        null,
      gX2:        null,
    },

  }),


  methods: {
    
    func_TableGrid_Group(data_) {
      let tbl = this.table
      tbl.dataSet = data_

      // *** Remove unnecessary data(OVERALL) in the table data ***
      // let splice = data_
      // let splice1  = []
      // splice.forEach((d,i)=> {
      //   if(d.PHASE != 'OVERALL' && d.GROUP == 'OVERALL' && d.SUBCONT == 'OVERALL') {
      //     if(splice.filter(f=> f.PHASE == d.PHASE).length < 4) return
      //   } 
      //   splice1.push(d)
      // })
      // tbl.dataSet = splice

      // let splice2 = []
      // splice1.forEach((d,i)=> {
      //   if(d.PHASE != 'OVERALL' && d.GROUP != 'OVERALL' && d.SUBCONT == 'OVERALL') {
      //     if(splice1.filter(f=> f.PHASE == d.PHASE && f.GROUP == d.GROUP).length < 3) return
      //   } 
      //   splice2.push(d)
      // })
      // tbl.dataSet = splice2


      tbl.dataSet.forEach((d,i)=> {
        if(d.PHASE == 'OVERALL' && d.GROUP == 'OVERALL' && d.SUBCONT == 'OVERALL') {d.bColor = '#E0E0DF'; d.tColor = '#000'}
        if(d.PHASE != 'OVERALL' && d.GROUP == 'OVERALL' && d.SUBCONT == 'OVERALL') {d.bColor = '#F4F5F5'; d.tColor = '#333'}
        if(d.PHASE != 'OVERALL' && d.GROUP != 'OVERALL' && d.SUBCONT == 'OVERALL') {d.bColor = '#fff'; d.tColor = '#333'}
        if(d.PHASE != 'OVERALL' && d.GROUP != 'OVERALL' && d.SUBCONT != 'OVERALL') {d.bColor = '#fff'; d.tColor = '#757575'}
      })

      // *** Preparing the required data for using columns in advance. ***
      let x_ = 0
      this.columns.forEach(d=> {
        d.v = x_
        if(d.align == 'start') d.x = x_ + tbl.padding
        if(d.align == 'middle') d.x = x_ + (d.width/2)
        if(d.align == 'end') d.x = x_ + d.width - + tbl.padding
        d.cx = x_ + (d.width/2)
        x_ = x_ + d.width
        
      })
      tbl.width = x_
      tbl.gX1 =this.columns[0].width 
      tbl.gX2 = this.columns[0].width + this.columns[1].width

      this.canvasTable = this.svg
      .append('g')
      .attr('transform', `translate(810, 50)`)

      this.draw_TableHeader() // =====> below
      this.draw_TableGrid() // =====> below
    },



    draw_TableHeader(){
      let tbl = this.table
      let header = this.canvasTable
      .append('g')
      .attr('transform', `translate(0.5, 15.5)`)

      header
      .append('text')
      .attr('x', 0).attr('y', -2)
      .attr('fill', '#000').attr('font-size', 13)
      .text('Weekly Manpower Allocation Status by Resource')

      header
      .append('line') // First Line
      .attr('x1', 0).attr('x2', tbl.width)
      .attr('y1', 5).attr('y2', 5).attr('stroke', '#333').attr('stroke-width', .5)

      header
      .append('line') // First Line
      .attr('x1', this.columns[4].v).attr('x2', tbl.width)
      .attr('y1', 25).attr('y2', 25).attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      this.columns.forEach((d,i)=> {
        header
        .append('text')
        .attr('x', d.cx).attr('y', 18)
        .attr('fill', '#000')
        .attr('font-size', 11).attr('text-anchor', 'middle')
        .text(d.title)

        header
        .append('text')
        .attr('x', d.cx).attr('y', (i<4) ? 27 : 36)
        .attr('fill', (i<4) ? '#000' : '#333')
        .attr('font-size', (i<4) ? 11 : 10).attr('text-anchor', 'middle')
        .text(d.item)

        if(i==0) return
        header
        .append('line') // Vertical line
        .attr('x1', d.v).attr('x2', d.v)
        .attr('y1', (i==5 || i==6 || i==8 || i==9) ? 25 : 5)
        .attr('y2', 38)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      })
    },



    draw_TableGrid(chtData, groupArr) {
      let tbl = this.table
      let tableGrid = this.canvasTable
      .append('g')
      .attr('transform', `translate(0.5, 68.5)`)

      tbl.dataSet.forEach((row,r)=> {
        
        let sY = (r * tbl.lineHeight + 6) - tbl.lineHeight
        let eY = r * tbl.lineHeight + 6

        this.columns.forEach((col,c)=> {
          
          tableGrid
          .append('rect') // Background
          .attr('x', col.v).attr('y', sY)
          .attr('width', col.width).attr('height', tbl.lineHeight+.5)
          .attr('fill', ()=> {
            let bc = row.bColor
            if(col.code=='PHASE' && r != tbl.dataSet.length-1) bc = '#F4F5F5'
            return bc
          })
            
          if(row[col.code] != 'OVERALL' && col.code != 'PHASE') {
            tableGrid
            .append('line') // Vertical line
            .attr('x1', col.v).attr('x2', col.v).attr('y1', sY).attr('y2', eY)
            .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
          }

          if(
            (r > 0 && 
            col.group == 'Y' && row[col.code] != 'HHI' &&
            tbl.dataSet[r-1][col.code] == row[col.code]) ||
            (row.PHASE == 'OVERALL' && col.code == 'PHASE' && row[col.code] == 'OVERALL') ||
            (row.PHASE == 'OVERALL' && col.code == 'SUBCONT' && row[col.code] == 'OVERALL')
          ) return

          tableGrid
          .append('text')
          .attr('x', (row.PHASE == 'OVERALL' && col.code == 'GROUP' && row[col.code] == 'OVERALL') ? col.cx : col.x)
          .attr('y', r * tbl.lineHeight)
          .attr('fill', ()=> {
            let color = row.tColor
            if (col.group == 'Y' && r<tbl.dataSet.length-1) color = '#333'
            else if(col.code == 'D_WK' || col.code == 'D_CUM') color = this.setDeltaColor(row[col.code])
            return color
          })
          .attr('font-size', 10)
          .attr('text-anchor', (row.PHASE == 'OVERALL' && col.code == 'GROUP' && row[col.code] == 'OVERALL') ? 'middle' : col.align)
          .text( ()=> {
            if(row.PHASE == 'OVERALL' && row[col.code] == 'OVERALL') return 'Global Total'
            else if(row.GROUP == 'OVERALL' && row[col.code] == 'OVERALL') return 'Group Total'
            else if(row.SUBCONT == 'OVERALL' && row[col.code] == 'OVERALL') return 'Sub Total'
            else return isNaN(row[col.code]) ? row[col.code] : this.AddComma(row[col.code])
          })
        })
        
        
        tableGrid
        .append('line') // Horizontal line
        .attr('x1', ()=> {
          let len = 0
          if(r < tbl.dataSet.length-2 && tbl.dataSet[r+1].PHASE == row.PHASE) len = tbl.gX1
          if(r < tbl.dataSet.length-2 && tbl.dataSet[r+1].GROUP == row.GROUP) len = tbl.gX2
          return len
        })
        .attr('x2', tbl.width)
        .attr('y1', eY).attr('y2', eY)
        .attr('stroke', ()=> {
          let color = '#bcbcbc'
          if(r < tbl.dataSet.length-2 && tbl.dataSet[r+1].PHASE != row.PHASE) color = '#333'
          
          if (r > tbl.dataSet.length-3) color = '#333'
          return color
        })
        .attr('stroke-width', .5)
      })

      tableGrid
      .append('line') // First Line
      .attr('x1', 0).attr('x2', tbl.width)
      .attr('y1', -13).attr('y2', -13).attr('stroke', '#333').attr('stroke-width', .5)

      // tableGrid
      // .append('line') // First Vertical Line
      // .attr('x1', 0).attr('x2', 0)
      // .attr('y1', -13).attr('y2', (tbl.dataSet.length-1) * tbl.lineHeight + 6).attr('stroke', '#333').attr('stroke-width', .5)

      // tableGrid
      // .append('line') // Last Vertical Line
      // .attr('x1', tbl.width).attr('x2', tbl.width)
      // .attr('y1', -13).attr('y2', (tbl.dataSet.length-1) * tbl.lineHeight + 6).attr('stroke', '#333').attr('stroke-width', .5)
    },
  }
}