
export default {
  data: () => ({

    localData: {
      // Skyline
      JSON1: [ 
        {      
          BOX_WIDTH:              29,
          BOX_HEIGHT:             15,
          BOX_GAP:                2, 
          BOX_TEXT_SIZE:          9,

          // Legends 
          LEGEND_X:               45,
          LEGEND_Y:               80,
          BAR_HEIGHT:             75,
        }
      ],


      // Checksheet
      JSON2: [ 
        {
          TITLE:            ' Cutting  / FAB.     / Assembly / Painting  / 3rd-PE   / Setting  ',
          SCHEDULE:         ' Finish   / Finish   / Finish   / Finish    / Start    / Start    ',
          JPG:              '          /          /          / A_        / C_       / D_       ',
          COLUMN:           ' BLK      / BLK      / BLK      / PE1       / PE3      / EREC     ',
          REF:              ' SC_PF    / FB_PF    / AS_PF    / PT_PF     / P3_PS    / ER_PS    ',
          PRO:              ' SC_AP    / FB_AP    / AS_AP    / PT_AP     / P3_AP    / ER_AP    ',
          COLOR:            ' #E0E0DF  / #FEED57  / #83D2F5  / #F7BACF   / #CDDC37  / #8BC248  ',
          WIDTH:            ' 31       / 31       / 31       / 31        / 31       / 31       ',
          START:            ' 1        / 2        / 4        / 4         / 6        / 1        ',
          END:              ' 1        / 2        / 4        / 4         / 6        / 1        ',
          JSON:             ' CUT      / FAB      / ASS      / PE1       / PE3      / EREC     ',
        }
      ],


      
    }
  }),
}