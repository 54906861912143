import * as d3 from 'd3'

export default {
  methods: {
    
    call_Search(input=null) {
      
      let data_List = JSON.parse(JSON.stringify(this.DataItems))
      this.search.results = []

      // apply search input ------------------------
      if(input != null) this.search.input = input
      input = input.toLowerCase()
      
      let targets = ['TAG', 'DESC']
      this.search.results = input ? data_List.filter(f => f.MOD == this.search.mod && f.LEV == this.search.lev && targets.some(col => f[col].toLowerCase().includes(input))) : []
      
      this.applySearch(input, this.search.results)
    },


    applySearch(input, _result) {

      if(input) {
        this.eqList.filter(f=> f.MOD == this.search.mod && f.LEV == this.search.lev).forEach(d=> {
          d3.select(`#NO-${d.ID}`).attr('visibility', 'hidden')
        })
        _result.filter(f=> f.MOD == this.search.mod && f.LEV == this.search.lev).forEach(d=> {
          d3.select(`#NO-${d.ID}`).attr('visibility', 'display')
        })
      } else {
        this.eqList.filter(f=> f.MOD == this.search.mod && f.LEV == this.search.lev).forEach(d=> {
          d3.select(`#NO-${d.ID}`).attr('visibility', 'display')
        })
      }

    },


    toggle_action(){
      this.eqList.filter(f=> f.MOD == this.search.mod && f.LEV == this.search.lev).forEach(d=> {

        let selectText = d3.select(`#NO-${d.ID}`).select('text')

        let cx = Number(d3.select(`#C-${d.ID}`).attr('cx')), tx = 0, ty = 0

        if (selectText.attr(`transform`)) {
          tx = Number(this.matrixCoordinate(selectText).x)
          ty = Number(this.matrixCoordinate(selectText).y)
          selectText.attr(`transform`, null)
        } else {
          tx = selectText.attr('x')
          ty = selectText.attr('y')
        }

        selectText
        .attr('x', (cx > tx) ? cx-10 : cx+10)
        .attr('y', ty)
        .attr('text-anchor', (cx > tx) ? 'end' : 'start')
        .text((this.Toggle=='on') ? d.TAG : d.DESC)
        
      })
    },

  }
}