import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 630,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 53,  A_WK: 70,  D_WK: 17,   P_CUM: 4029,  A_CUM: 3519,  D_CUM: -510, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 53,  A_WK: 72,  D_WK: 20,   P_CUM: 4082,  A_CUM: 3592,  D_CUM: -490, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 53,  A_WK: 69,  D_WK: 16,   P_CUM: 4135,  A_CUM: 3661,  D_CUM: -474, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 53,  A_WK: 65,  D_WK: 12,   P_CUM: 4188,  A_CUM: 3725,  D_CUM: -463, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 54,  D_WK: 12,   P_CUM: 4230,  A_CUM: 3779,  D_CUM: -451, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 54,  D_WK: 11,   P_CUM: 4272,  A_CUM: 3833,  D_CUM: -439, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 63,  D_WK: 21,   P_CUM: 4315,  A_CUM: 3896,  D_CUM: -418, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 58,  D_WK: 16,   P_CUM: 4357,  A_CUM: 3955,  D_CUM: -402, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 36,  A_WK: 54,  D_WK: 18,   P_CUM: 4393,  A_CUM: 4008,  D_CUM: -385, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 36,  A_WK: 53,  D_WK: 17,   P_CUM: 4429,  A_CUM: 4062,  D_CUM: -368, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 36,  A_WK: 53,  D_WK: 17,   P_CUM: 4465,  A_CUM: 4115,  D_CUM: -351, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 36,  A_WK: 60,  D_WK: 24,   P_CUM: 4501,  A_CUM: 4175,  D_CUM: -326, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 36,  A_WK: 67,  D_WK: 31,   P_CUM: 4537,  A_CUM: 4242,  D_CUM: -296, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 31,  A_WK: 56,  D_WK: 25,   P_CUM: 4569,  A_CUM: 4298,  D_CUM: -270, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 31,  A_WK: 57,  D_WK: 25,   P_CUM: 4600,  A_CUM: 4355,  D_CUM: -245, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 31,  A_WK: 58,  D_WK: 26,   P_CUM: 4631,  A_CUM: 4412,  D_CUM: -219, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 31,  A_WK: 56,  D_WK: 25,   P_CUM: 4662,  A_CUM: 4469,  D_CUM: -194, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 22,  A_WK: 54,  D_WK: 32,   P_CUM: 4685,  A_CUM: 4523,  D_CUM: -162, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 22,  A_WK: 51,  D_WK: 29,   P_CUM: 4707,  A_CUM: 4574,  D_CUM: -133, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 22,  A_WK: 50,  D_WK: 28,   P_CUM: 4729,  A_CUM: 4625,  D_CUM: -105, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 22,  A_WK: 49,  D_WK: 27,   P_CUM: 4752,  A_CUM: 4674,  D_CUM: -78, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 18,  A_WK: 45,  D_WK: 27,   P_CUM: 4770,  A_CUM: 4719,  D_CUM: -51, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 18,  A_WK: 48,  D_WK: 30,   P_CUM: 4788,  A_CUM: 4767,  D_CUM: -21, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 50,  D_WK: 8,    P_CUM: 4831,  A_CUM: 4817,  D_CUM: -13, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 44,  D_WK: 2,    P_CUM: 4873,  A_CUM: 4862,  D_CUM: -11, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 42,  A_WK: 23,  D_WK: -20,  P_CUM: 4915,  A_CUM: 4884,  D_CUM: -31, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 29,  A_WK: 38,  D_WK: 9,    P_CUM: 4944,  A_CUM: 4922,  D_CUM: -23, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 29,  A_WK: 38,  D_WK: 9,    P_CUM: 4973,  A_CUM: 4959,  D_CUM: -14, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 29,  A_WK: 38,  D_WK: 9,    P_CUM: 5002,  A_CUM: 4997,  D_CUM: -6, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 29,  A_WK: 29,  D_WK: 0,    P_CUM: 5031,  A_CUM: 5026,  D_CUM: -6, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 5042,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 5053,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 5063,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 5074,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 5085,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 5,   A_WK: 0,   D_WK: 0,    P_CUM: 5090,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 5,   A_WK: 0,   D_WK: 0,    P_CUM: 5094,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 5,   A_WK: 0,   D_WK: 0,    P_CUM: 5099,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 5,   A_WK: 0,   D_WK: 0,    P_CUM: 5104,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 5107,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 5110,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 5113,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 5116,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 5118,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 5120,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 5122,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 5124,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 1,   A_WK: 0,   D_WK: 0,    P_CUM: 5125,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 1,   A_WK: 0,   D_WK: 0,    P_CUM: 5126,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 1,   A_WK: 0,   D_WK: 0,    P_CUM: 5127,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 1,   A_WK: 0,   D_WK: 0,    P_CUM: 5129,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'Exmar',   TOTAL: 5228,  P_WK: 1,   A_WK: 0,   D_WK: 0,    P_CUM: 5130,  A_CUM: 5026,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 134, A_WK: 149, D_WK: 15,   P_CUM: 3861,  A_CUM: 3707,  D_CUM: -155, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 134, A_WK: 163, D_WK: 29,   P_CUM: 3995,  A_CUM: 3869,  D_CUM: -126, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 134, A_WK: 170, D_WK: 36,   P_CUM: 4129,  A_CUM: 4039,  D_CUM: -90, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 131, A_WK: 176, D_WK: 45,   P_CUM: 4260,  A_CUM: 4216,  D_CUM: -44, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 131, A_WK: 177, D_WK: 46,   P_CUM: 4391,  A_CUM: 4392,  D_CUM: 1, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 185, A_WK: 174, D_WK: -11,  P_CUM: 4576,  A_CUM: 4566,  D_CUM: -10, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 184, A_WK: 177, D_WK: -7,   P_CUM: 4759,  A_CUM: 4742,  D_CUM: -17, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 182, A_WK: 176, D_WK: -6,   P_CUM: 4941,  A_CUM: 4918,  D_CUM: -23, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 177, A_WK: 25,  D_WK: -152, P_CUM: 5118,  A_CUM: 4943,  D_CUM: -175, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 28,  A_WK: 151, D_WK: 123,  P_CUM: 5145,  A_CUM: 5094,  D_CUM: -51, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 178, A_WK: 163, D_WK: -14,  P_CUM: 5323,  A_CUM: 5257,  D_CUM: -66, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 178, A_WK: 190, D_WK: 12,   P_CUM: 5500,  A_CUM: 5447,  D_CUM: -53, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 177, A_WK: 172, D_WK: -5,   P_CUM: 5677,  A_CUM: 5619,  D_CUM: -58, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 168, A_WK: 169, D_WK: 1,    P_CUM: 5845,  A_CUM: 5788,  D_CUM: -57, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 167, A_WK: 165, D_WK: -2,   P_CUM: 6012,  A_CUM: 5953,  D_CUM: -59, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 163, A_WK: 147, D_WK: -16,  P_CUM: 6175,  A_CUM: 6101,  D_CUM: -74, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 163, A_WK: 155, D_WK: -8,   P_CUM: 6338,  A_CUM: 6255,  D_CUM: -82, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 149, A_WK: 152, D_WK: 3,    P_CUM: 6486,  A_CUM: 6407,  D_CUM: -79, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 148, A_WK: 152, D_WK: 4,    P_CUM: 6634,  A_CUM: 6559,  D_CUM: -75, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 145, A_WK: 158, D_WK: 12,   P_CUM: 6779,  A_CUM: 6717,  D_CUM: -63, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 145, A_WK: 158, D_WK: 13,   P_CUM: 6924,  A_CUM: 6875,  D_CUM: -49, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 125, A_WK: 152, D_WK: 28,   P_CUM: 7049,  A_CUM: 7027,  D_CUM: -22, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 125, A_WK: 155, D_WK: 30,   P_CUM: 7174,  A_CUM: 7182,  D_CUM: 8, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 129, A_WK: 160, D_WK: 32,   P_CUM: 7303,  A_CUM: 7342,  D_CUM: 40, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 128, A_WK: 159, D_WK: 31,   P_CUM: 7431,  A_CUM: 7501,  D_CUM: 70, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 127, A_WK: 166, D_WK: 39,   P_CUM: 7558,  A_CUM: 7667,  D_CUM: 109, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 116, A_WK: 168, D_WK: 52,   P_CUM: 7673,  A_CUM: 7835,  D_CUM: 161, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 116, A_WK: 150, D_WK: 34,   P_CUM: 7789,  A_CUM: 7985,  D_CUM: 196, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 114, A_WK: 150, D_WK: 36,   P_CUM: 7903,  A_CUM: 8135,  D_CUM: 232, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 113, A_WK: 114, D_WK: 2,    P_CUM: 8016,  A_CUM: 8249,  D_CUM: 233, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 102, A_WK: 0,   D_WK: 0,    P_CUM: 8118,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 102, A_WK: 0,   D_WK: 0,    P_CUM: 8220,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 101, A_WK: 0,   D_WK: 0,    P_CUM: 8321,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 101, A_WK: 0,   D_WK: 0,    P_CUM: 8422,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 99,  A_WK: 0,   D_WK: 0,    P_CUM: 8520,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 91,  A_WK: 0,   D_WK: 0,    P_CUM: 8611,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 90,  A_WK: 0,   D_WK: 0,    P_CUM: 8701,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 89,  A_WK: 0,   D_WK: 0,    P_CUM: 8790,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 83,  A_WK: 0,   D_WK: 0,    P_CUM: 8873,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 62,  A_WK: 0,   D_WK: 0,    P_CUM: 8935,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 61,  A_WK: 0,   D_WK: 0,    P_CUM: 8996,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 61,  A_WK: 0,   D_WK: 0,    P_CUM: 9056,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 60,  A_WK: 0,   D_WK: 0,    P_CUM: 9116,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 49,  A_WK: 0,   D_WK: 0,    P_CUM: 9165,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 49,  A_WK: 0,   D_WK: 0,    P_CUM: 9214,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 49,  A_WK: 0,   D_WK: 0,    P_CUM: 9263,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 49,  A_WK: 0,   D_WK: 0,    P_CUM: 9312,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 38,  A_WK: 0,   D_WK: 0,    P_CUM: 9349,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 37,  A_WK: 0,   D_WK: 0,    P_CUM: 9386,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 37,  A_WK: 0,   D_WK: 0,    P_CUM: 9424,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 37,  A_WK: 0,   D_WK: 0,    P_CUM: 9460,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'HHI',     TOTAL: 9461,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 9460,  A_CUM: 8249,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 218, A_WK: 223, D_WK: 5,    P_CUM: 8969,  A_CUM: 8363,  D_CUM: -606, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 218, A_WK: 228, D_WK: 10,   P_CUM: 9187,  A_CUM: 8591,  D_CUM: -596, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 218, A_WK: 251, D_WK: 33,   P_CUM: 9404,  A_CUM: 8842,  D_CUM: -563, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 214, A_WK: 252, D_WK: 38,   P_CUM: 9618,  A_CUM: 9094,  D_CUM: -524, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 214, A_WK: 194, D_WK: -20,  P_CUM: 9832,  A_CUM: 9288,  D_CUM: -545, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 214, A_WK: 233, D_WK: 19,   P_CUM: 10046, A_CUM: 9521,  D_CUM: -526, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 214, A_WK: 257, D_WK: 43,   P_CUM: 10260, A_CUM: 9778,  D_CUM: -483, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 208, A_WK: 264, D_WK: 57,   P_CUM: 10468, A_CUM: 10042, D_CUM: -426, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 208, A_WK: 271, D_WK: 63,   P_CUM: 10675, A_CUM: 10312, D_CUM: -363, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 208, A_WK: 241, D_WK: 33,   P_CUM: 10883, A_CUM: 10553, D_CUM: -330, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 208, A_WK: 263, D_WK: 55,   P_CUM: 11091, A_CUM: 10816, D_CUM: -274, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 208, A_WK: 252, D_WK: 45,   P_CUM: 11298, A_CUM: 11068, D_CUM: -230, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 196, A_WK: 275, D_WK: 79,   P_CUM: 11494, A_CUM: 11343, D_CUM: -151, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 196, A_WK: 239, D_WK: 42,   P_CUM: 11691, A_CUM: 11582, D_CUM: -109, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 196, A_WK: 272, D_WK: 76,   P_CUM: 11887, A_CUM: 11854, D_CUM: -33, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 196, A_WK: 274, D_WK: 78,   P_CUM: 12083, A_CUM: 12128, D_CUM: 45, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 188, A_WK: 277, D_WK: 89,   P_CUM: 12271, A_CUM: 12405, D_CUM: 134, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 188, A_WK: 275, D_WK: 86,   P_CUM: 12460, A_CUM: 12680, D_CUM: 220, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 188, A_WK: 269, D_WK: 81,   P_CUM: 12648, A_CUM: 12949, D_CUM: 301, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 188, A_WK: 243, D_WK: 55,   P_CUM: 12837, A_CUM: 13192, D_CUM: 355, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 170, A_WK: 271, D_WK: 101,  P_CUM: 13006, A_CUM: 13463, D_CUM: 456, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 170, A_WK: 268, D_WK: 98,   P_CUM: 13176, A_CUM: 13730, D_CUM: 554, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 170, A_WK: 247, D_WK: 77,   P_CUM: 13346, A_CUM: 13977, D_CUM: 631, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 273, A_WK: 252, D_WK: -21,  P_CUM: 13619, A_CUM: 14229, D_CUM: 610, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 273, A_WK: 267, D_WK: -6,   P_CUM: 13891, A_CUM: 14496, D_CUM: 605, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 210, A_WK: 195, D_WK: -15,  P_CUM: 14101, A_CUM: 14690, D_CUM: 590, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 231, A_WK: 225, D_WK: -6,   P_CUM: 14332, A_CUM: 14915, D_CUM: 584, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 225, A_WK: 225, D_WK: 0,    P_CUM: 14557, A_CUM: 15140, D_CUM: 584, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 218, A_WK: 225, D_WK: 7,    P_CUM: 14774, A_CUM: 15365, D_CUM: 591, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 146, A_WK: 218, D_WK: 72,   P_CUM: 14921, A_CUM: 15583, D_CUM: 663, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 163, A_WK: 0,   D_WK: 0,    P_CUM: 15084, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 182, A_WK: 0,   D_WK: 0,    P_CUM: 15266, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 179, A_WK: 0,   D_WK: 0,    P_CUM: 15445, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 177, A_WK: 0,   D_WK: 0,    P_CUM: 15622, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 178, A_WK: 0,   D_WK: 0,    P_CUM: 15799, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 169, A_WK: 0,   D_WK: 0,    P_CUM: 15968, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 167, A_WK: 0,   D_WK: 0,    P_CUM: 16135, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 165, A_WK: 0,   D_WK: 0,    P_CUM: 16300, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 162, A_WK: 0,   D_WK: 0,    P_CUM: 16462, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 136, A_WK: 0,   D_WK: 0,    P_CUM: 16598, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 133, A_WK: 0,   D_WK: 0,    P_CUM: 16730, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 130, A_WK: 0,   D_WK: 0,    P_CUM: 16860, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 129, A_WK: 0,   D_WK: 0,    P_CUM: 16989, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 115, A_WK: 0,   D_WK: 0,    P_CUM: 17104, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 111, A_WK: 0,   D_WK: 0,    P_CUM: 17215, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 104, A_WK: 0,   D_WK: 0,    P_CUM: 17319, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 102, A_WK: 0,   D_WK: 0,    P_CUM: 17421, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 73,  A_WK: 0,   D_WK: 0,    P_CUM: 17494, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 70,  A_WK: 0,   D_WK: 0,    P_CUM: 17564, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 48,  A_WK: 0,   D_WK: 0,    P_CUM: 17611, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 35,  A_WK: 0,   D_WK: 0,    P_CUM: 17646, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'WOOD',    TOTAL: 17673, P_WK: 27,  A_WK: 0,   D_WK: 0,    P_CUM: 17673, A_CUM: 15583, D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 404, A_WK: 442, D_WK: 37,   P_CUM: 16859, A_CUM: 15589, D_CUM: -1270, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 404, A_WK: 463, D_WK: 59,   P_CUM: 17264, A_CUM: 16052, D_CUM: -1212, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 404, A_WK: 490, D_WK: 85,   P_CUM: 17668, A_CUM: 16541, D_CUM: -1127, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 398, A_WK: 493, D_WK: 95,   P_CUM: 18066, A_CUM: 17034, D_CUM: -1031, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 387, A_WK: 425, D_WK: 37,   P_CUM: 18453, A_CUM: 17459, D_CUM: -994, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 441, A_WK: 460, D_WK: 19,   P_CUM: 18894, A_CUM: 17919, D_CUM: -975, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 440, A_WK: 497, D_WK: 57,   P_CUM: 19334, A_CUM: 18416, D_CUM: -918, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 432, A_WK: 498, D_WK: 66,   P_CUM: 19766, A_CUM: 18915, D_CUM: -852, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 420, A_WK: 349, D_WK: -71,  P_CUM: 20186, A_CUM: 19264, D_CUM: -923, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 271, A_WK: 445, D_WK: 174,  P_CUM: 20458, A_CUM: 19709, D_CUM: -749, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 421, A_WK: 479, D_WK: 58,   P_CUM: 20879, A_CUM: 20188, D_CUM: -691, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 421, A_WK: 502, D_WK: 81,   P_CUM: 21300, A_CUM: 20690, D_CUM: -610, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 409, A_WK: 514, D_WK: 105,  P_CUM: 21709, A_CUM: 21204, D_CUM: -505, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 396, A_WK: 464, D_WK: 69,   P_CUM: 22104, A_CUM: 21668, D_CUM: -436, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 394, A_WK: 493, D_WK: 99,   P_CUM: 22498, A_CUM: 22162, D_CUM: -337, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 391, A_WK: 479, D_WK: 88,   P_CUM: 22889, A_CUM: 22641, D_CUM: -248, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 382, A_WK: 488, D_WK: 106,  P_CUM: 23271, A_CUM: 23129, D_CUM: -143, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 359, A_WK: 481, D_WK: 122,  P_CUM: 23631, A_CUM: 23610, D_CUM: -21, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 358, A_WK: 472, D_WK: 114,  P_CUM: 23989, A_CUM: 24082, D_CUM: 93, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 356, A_WK: 451, D_WK: 95,   P_CUM: 24345, A_CUM: 24533, D_CUM: 188, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 337, A_WK: 479, D_WK: 141,  P_CUM: 24683, A_CUM: 25012, D_CUM: 329, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 313, A_WK: 465, D_WK: 152,  P_CUM: 24995, A_CUM: 25477, D_CUM: 481, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 313, A_WK: 450, D_WK: 136,  P_CUM: 25309, A_CUM: 25926, D_CUM: 618, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 444, A_WK: 462, D_WK: 19,   P_CUM: 25752, A_CUM: 26389, D_CUM: 636, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 443, A_WK: 470, D_WK: 27,   P_CUM: 26195, A_CUM: 26859, D_CUM: 664, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 379, A_WK: 383, D_WK: 4,    P_CUM: 26574, A_CUM: 27241, D_CUM: 668, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 376, A_WK: 431, D_WK: 55,   P_CUM: 26950, A_CUM: 27672, D_CUM: 722, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 370, A_WK: 413, D_WK: 43,   P_CUM: 27319, A_CUM: 28085, D_CUM: 765, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 361, A_WK: 413, D_WK: 52,   P_CUM: 27680, A_CUM: 28497, D_CUM: 817, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 288, A_WK: 361, D_WK: 73,   P_CUM: 27968, A_CUM: 28858, D_CUM: 890, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 276, A_WK: 0,   D_WK: 0,    P_CUM: 28243, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 295, A_WK: 0,   D_WK: 0,    P_CUM: 28538, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 291, A_WK: 0,   D_WK: 0,    P_CUM: 28829, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 289, A_WK: 0,   D_WK: 0,    P_CUM: 29118, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 287, A_WK: 0,   D_WK: 0,    P_CUM: 29405, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 264, A_WK: 0,   D_WK: 0,    P_CUM: 29668, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 262, A_WK: 0,   D_WK: 0,    P_CUM: 29930, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 259, A_WK: 0,   D_WK: 0,    P_CUM: 30189, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 250, A_WK: 0,   D_WK: 0,    P_CUM: 30439, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 200, A_WK: 0,   D_WK: 0,    P_CUM: 30639, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 197, A_WK: 0,   D_WK: 0,    P_CUM: 30836, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 194, A_WK: 0,   D_WK: 0,    P_CUM: 31029, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 192, A_WK: 0,   D_WK: 0,    P_CUM: 31221, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 166, A_WK: 0,   D_WK: 0,    P_CUM: 31387, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 162, A_WK: 0,   D_WK: 0,    P_CUM: 31549, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 155, A_WK: 0,   D_WK: 0,    P_CUM: 31704, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 152, A_WK: 0,   D_WK: 0,    P_CUM: 31856, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 112, A_WK: 0,   D_WK: 0,    P_CUM: 31968, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 108, A_WK: 0,   D_WK: 0,    P_CUM: 32076, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 86,  A_WK: 0,   D_WK: 0,    P_CUM: 32162, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 73,  A_WK: 0,   D_WK: 0,    P_CUM: 32235, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Detail Eng',     SUBCONT: 'OVERALL', TOTAL: 32362, P_WK: 28,  A_WK: 0,   D_WK: 0,    P_CUM: 32263, A_CUM: 28858, D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 13,    A_CUM: 13,    D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 26,    A_CUM: 26,    D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 5,   A_WK: 5,   D_WK: 0,    P_CUM: 31,    A_CUM: 31,    D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 44,    A_CUM: 44,    D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 58,    A_CUM: 58,    D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 71,    A_CUM: 71,    D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 15,  A_WK: 15,  D_WK: 0,    P_CUM: 86,    A_CUM: 86,    D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 17,  A_WK: 17,  D_WK: 0,    P_CUM: 103,   A_CUM: 103,   D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 40,  A_WK: 40,  D_WK: 0,    P_CUM: 143,   A_CUM: 143,   D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 23,  A_WK: 23,  D_WK: 0,    P_CUM: 166,   A_CUM: 166,   D_CUM: 0, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 38,  A_WK: 42,  D_WK: 4,    P_CUM: 204,   A_CUM: 208,   D_CUM: 4, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 62,  A_WK: 44,  D_WK: -19,  P_CUM: 267,   A_CUM: 252,   D_CUM: -15, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 83,  A_WK: 43,  D_WK: -40,  P_CUM: 350,   A_CUM: 295,   D_CUM: -55, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 99,  A_WK: 51,  D_WK: -49,  P_CUM: 449,   A_CUM: 346,   D_CUM: -103, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 97,  A_WK: 58,  D_WK: -39,  P_CUM: 546,   A_CUM: 404,   D_CUM: -142, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 90,  A_WK: 58,  D_WK: -31,  P_CUM: 635,   A_CUM: 462,   D_CUM: -174, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 88,  A_WK: 41,  D_WK: -47,  P_CUM: 723,   A_CUM: 503,   D_CUM: -221, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 127, A_WK: 0,   D_WK: 0,    P_CUM: 850,   A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 174, A_WK: 0,   D_WK: 0,    P_CUM: 1024,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 179, A_WK: 0,   D_WK: 0,    P_CUM: 1203,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 160, A_WK: 0,   D_WK: 0,    P_CUM: 1362,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 210, A_WK: 0,   D_WK: 0,    P_CUM: 1572,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 172, A_WK: 0,   D_WK: 0,    P_CUM: 1744,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 242, A_WK: 0,   D_WK: 0,    P_CUM: 1987,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 281, A_WK: 0,   D_WK: 0,    P_CUM: 2268,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 292, A_WK: 0,   D_WK: 0,    P_CUM: 2559,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 349, A_WK: 0,   D_WK: 0,    P_CUM: 2908,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 358, A_WK: 0,   D_WK: 0,    P_CUM: 3267,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 310, A_WK: 0,   D_WK: 0,    P_CUM: 3577,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 419, A_WK: 0,   D_WK: 0,    P_CUM: 3995,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 385, A_WK: 0,   D_WK: 0,    P_CUM: 4380,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 301, A_WK: 0,   D_WK: 0,    P_CUM: 4681,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 278, A_WK: 0,   D_WK: 0,    P_CUM: 4959,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 252, A_WK: 0,   D_WK: 0,    P_CUM: 5211,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 507, A_WK: 0,   D_WK: 0,    P_CUM: 5717,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 224, A_WK: 0,   D_WK: 0,    P_CUM: 5941,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 393, A_WK: 0,   D_WK: 0,    P_CUM: 6334,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 302, A_WK: 0,   D_WK: 0,    P_CUM: 6636,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'HHI',     TOTAL: 12253, P_WK: 326, A_WK: 0,   D_WK: 0,    P_CUM: 6961,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 13,    A_CUM: 13,    D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 26,    A_CUM: 26,    D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 5,   A_WK: 5,   D_WK: 0,    P_CUM: 31,    A_CUM: 31,    D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 44,    A_CUM: 44,    D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 58,    A_CUM: 58,    D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 13,  A_WK: 13,  D_WK: 0,    P_CUM: 71,    A_CUM: 71,    D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 15,  A_WK: 15,  D_WK: 0,    P_CUM: 86,    A_CUM: 86,    D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 17,  A_WK: 17,  D_WK: 0,    P_CUM: 103,   A_CUM: 103,   D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 40,  A_WK: 40,  D_WK: 0,    P_CUM: 143,   A_CUM: 143,   D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 23,  A_WK: 23,  D_WK: 0,    P_CUM: 166,   A_CUM: 166,   D_CUM: 0, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 38,  A_WK: 42,  D_WK: 4,    P_CUM: 204,   A_CUM: 208,   D_CUM: 4, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 62,  A_WK: 44,  D_WK: -19,  P_CUM: 267,   A_CUM: 252,   D_CUM: -15, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 83,  A_WK: 43,  D_WK: -40,  P_CUM: 350,   A_CUM: 295,   D_CUM: -55, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 99,  A_WK: 51,  D_WK: -49,  P_CUM: 449,   A_CUM: 346,   D_CUM: -103, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 97,  A_WK: 58,  D_WK: -39,  P_CUM: 546,   A_CUM: 404,   D_CUM: -142, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 90,  A_WK: 58,  D_WK: -31,  P_CUM: 635,   A_CUM: 462,   D_CUM: -174, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 88,  A_WK: 41,  D_WK: -47,  P_CUM: 723,   A_CUM: 503,   D_CUM: -221, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 127, A_WK: 0,   D_WK: 0,    P_CUM: 850,   A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 174, A_WK: 0,   D_WK: 0,    P_CUM: 1024,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 179, A_WK: 0,   D_WK: 0,    P_CUM: 1203,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 160, A_WK: 0,   D_WK: 0,    P_CUM: 1362,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 210, A_WK: 0,   D_WK: 0,    P_CUM: 1572,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 172, A_WK: 0,   D_WK: 0,    P_CUM: 1744,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 242, A_WK: 0,   D_WK: 0,    P_CUM: 1987,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 281, A_WK: 0,   D_WK: 0,    P_CUM: 2268,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 292, A_WK: 0,   D_WK: 0,    P_CUM: 2559,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 349, A_WK: 0,   D_WK: 0,    P_CUM: 2908,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 358, A_WK: 0,   D_WK: 0,    P_CUM: 3267,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 310, A_WK: 0,   D_WK: 0,    P_CUM: 3577,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 419, A_WK: 0,   D_WK: 0,    P_CUM: 3995,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 385, A_WK: 0,   D_WK: 0,    P_CUM: 4380,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 301, A_WK: 0,   D_WK: 0,    P_CUM: 4681,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 278, A_WK: 0,   D_WK: 0,    P_CUM: 4959,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 252, A_WK: 0,   D_WK: 0,    P_CUM: 5211,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 507, A_WK: 0,   D_WK: 0,    P_CUM: 5717,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 224, A_WK: 0,   D_WK: 0,    P_CUM: 5941,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 393, A_WK: 0,   D_WK: 0,    P_CUM: 6334,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 302, A_WK: 0,   D_WK: 0,    P_CUM: 6636,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Shop Drawing',   SUBCONT: 'OVERALL', TOTAL: 12253, P_WK: 326, A_WK: 0,   D_WK: 0,    P_CUM: 6961,  A_CUM: 503,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 4,     A_CUM: 2,     D_CUM: -2, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 5,     A_CUM: 3,     D_CUM: -2, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 6,     A_CUM: 4,     D_CUM: -2, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: -1,   P_CUM: 7,     A_CUM: 4,     D_CUM: -3, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 8,     A_CUM: 5,     D_CUM: -3, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 9,     A_CUM: 6,     D_CUM: -3, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 10,    A_CUM: 7,     D_CUM: -3, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 3,   D_WK: 2,    P_CUM: 11,    A_CUM: 10,    D_CUM: -1, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 0,   D_WK: -1,   P_CUM: 12,    A_CUM: 10,    D_CUM: -2, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 0,   D_WK: -1,   P_CUM: 13,    A_CUM: 10,    D_CUM: -3, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 14,    A_CUM: 11,    D_CUM: -3, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 15,    A_CUM: 12,    D_CUM: -4, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 16,    A_CUM: 12,    D_CUM: -4, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 17,    A_CUM: 13,    D_CUM: -4, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 18,    A_CUM: 13,    D_CUM: -5, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 0,   D_WK: -1,   P_CUM: 19,    A_CUM: 14,    D_CUM: -5, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 2,   D_WK: 1,    P_CUM: 20,    A_CUM: 16,    D_CUM: -5, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 21,    A_CUM: 16,    D_CUM: -5, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 22,    A_CUM: 17,    D_CUM: -5, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 23,    A_CUM: 18,    D_CUM: -5, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 1,   A_WK: 1,   D_WK: 0,    P_CUM: 24,    A_CUM: 19,    D_CUM: -5, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 26,    A_CUM: 20,    D_CUM: -6, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 28,    A_CUM: 20,    D_CUM: -8, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 30,    A_CUM: 22,    D_CUM: -8, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 32,    A_CUM: 24,    D_CUM: -9, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 34,    A_CUM: 24,    D_CUM: -10, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 36,    A_CUM: 25,    D_CUM: -11, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 38,    A_CUM: 27,    D_CUM: -12, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 40,    A_CUM: 28,    D_CUM: -12, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 42,    A_CUM: 29,    D_CUM: -13, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 44,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 46,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 48,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 50,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 52,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 54,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 56,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 58,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 60,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 62,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 64,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 66,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 2,   A_WK: 0,   D_WK: 0,    P_CUM: 68,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 71,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 74,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 77,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 80,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 83,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 86,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 89,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 92,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'HHI',     TOTAL: 185,   P_WK: 3,   A_WK: 0,   D_WK: 0,    P_CUM: 95,    A_CUM: 29,    D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 25,    A_CUM: 23,    D_CUM: -3, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 27,    A_CUM: 25,    D_CUM: -3, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 29,    A_CUM: 27,    D_CUM: -3, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 31,    A_CUM: 29,    D_CUM: -3, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 1,   D_WK: -1,   P_CUM: 33,    A_CUM: 30,    D_CUM: -3, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 35,    A_CUM: 32,    D_CUM: -3, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 37,    A_CUM: 34,    D_CUM: -3, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 39,    A_CUM: 36,    D_CUM: -3, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 41,    A_CUM: 38,    D_CUM: -3, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 43,    A_CUM: 40,    D_CUM: -3, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 45,    A_CUM: 42,    D_CUM: -3, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 47,    A_CUM: 44,    D_CUM: -3, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 49,    A_CUM: 46,    D_CUM: -3, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 51,    A_CUM: 48,    D_CUM: -3, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 53,    A_CUM: 50,    D_CUM: -3, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 55,    A_CUM: 52,    D_CUM: -3, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 57,    A_CUM: 54,    D_CUM: -3, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 59,    A_CUM: 56,    D_CUM: -3, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 61,    A_CUM: 58,    D_CUM: -3, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 63,    A_CUM: 60,    D_CUM: -3, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 2,   A_WK: 2,   D_WK: 0,    P_CUM: 65,    A_CUM: 62,    D_CUM: -3, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 68,    A_CUM: 64,    D_CUM: -4, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 71,    A_CUM: 66,    D_CUM: -5, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 74,    A_CUM: 68,    D_CUM: -6, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 77,    A_CUM: 70,    D_CUM: -7, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 1,   D_WK: -2,   P_CUM: 80,    A_CUM: 71,    D_CUM: -9, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 83,    A_CUM: 73,    D_CUM: -10, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 86,    A_CUM: 76,    D_CUM: -10, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 89,    A_CUM: 79,    D_CUM: -10, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 3,   A_WK: 0,   D_WK: -3,   P_CUM: 92,    A_CUM: 80,    D_CUM: -12, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 100,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 108,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 116,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 124,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 132,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 140,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 148,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 156,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 164,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 172,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 180,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 188,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 196,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 204,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 212,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 220,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 228,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 236,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 244,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 252,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 260,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'Gate',    TOTAL: 551,   P_WK: 8,   A_WK: 0,   D_WK: 0,    P_CUM: 268,   A_CUM: 80,    D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 29,    A_CUM: 25,    D_CUM: -4, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 32,    A_CUM: 27,    D_CUM: -5, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 35,    A_CUM: 30,    D_CUM: -5, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: -1,   P_CUM: 38,    A_CUM: 33,    D_CUM: -5, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 41,    A_CUM: 35,    D_CUM: -6, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 44,    A_CUM: 38,    D_CUM: -6, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 47,    A_CUM: 41,    D_CUM: -7, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 5,   D_WK: 2,    P_CUM: 50,    A_CUM: 46,    D_CUM: -4, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 53,    A_CUM: 48,    D_CUM: -5, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 56,    A_CUM: 50,    D_CUM: -6, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 59,    A_CUM: 53,    D_CUM: -6, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 62,    A_CUM: 55,    D_CUM: -7, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 65,    A_CUM: 58,    D_CUM: -7, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 68,    A_CUM: 61,    D_CUM: -7, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 71,    A_CUM: 63,    D_CUM: -8, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 2,   D_WK: -1,   P_CUM: 74,    A_CUM: 66,    D_CUM: -8, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 4,   D_WK: 1,    P_CUM: 77,    A_CUM: 69,    D_CUM: -8, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 80,    A_CUM: 72,    D_CUM: -8, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 83,    A_CUM: 75,    D_CUM: -8, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 86,    A_CUM: 78,    D_CUM: -8, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 3,   A_WK: 3,   D_WK: 0,    P_CUM: 89,    A_CUM: 81,    D_CUM: -8, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 3,   D_WK: -2,   P_CUM: 94,    A_CUM: 84,    D_CUM: -10, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 3,   D_WK: -2,   P_CUM: 99,    A_CUM: 86,    D_CUM: -13, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 4,   D_WK: -1,   P_CUM: 104,   A_CUM: 90,    D_CUM: -14, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 3,   D_WK: -2,   P_CUM: 109,   A_CUM: 93,    D_CUM: -16, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 2,   D_WK: -3,   P_CUM: 114,   A_CUM: 96,    D_CUM: -18, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 3,   D_WK: -2,   P_CUM: 119,   A_CUM: 98,    D_CUM: -21, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 4,   D_WK: -1,   P_CUM: 124,   A_CUM: 103,   D_CUM: -21, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 4,   D_WK: -1,   P_CUM: 129,   A_CUM: 107,   D_CUM: -22, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 5,   A_WK: 2,   D_WK: -3,   P_CUM: 134,   A_CUM: 109,   D_CUM: -25, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 144,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 154,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 164,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 174,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 184,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 194,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 204,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 214,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 224,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 234,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 244,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 254,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 10,  A_WK: 0,   D_WK: 0,    P_CUM: 264,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 275,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 286,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 297,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 308,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 319,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 330,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 341,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 352,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'Commissing Eng', SUBCONT: 'OVERALL', TOTAL: 736,   P_WK: 11,  A_WK: 0,   D_WK: 0,    P_CUM: 363,   A_CUM: 109,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 407, A_WK: 444, D_WK: 37,   P_CUM: 16888, A_CUM: 15613, D_CUM: -1275, },
      { CDATE: '2024-06-14', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 407, A_WK: 466, D_WK: 58,   P_CUM: 17296, A_CUM: 16079, D_CUM: -1217, },
      { CDATE: '2024-06-21', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 407, A_WK: 493, D_WK: 85,   P_CUM: 17703, A_CUM: 16572, D_CUM: -1131, },
      { CDATE: '2024-06-28', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 401, A_WK: 496, D_WK: 95,   P_CUM: 18104, A_CUM: 17067, D_CUM: -1037, },
      { CDATE: '2024-07-05', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 390, A_WK: 427, D_WK: 36,   P_CUM: 18494, A_CUM: 17494, D_CUM: -1000, },
      { CDATE: '2024-07-12', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 444, A_WK: 463, D_WK: 19,   P_CUM: 18938, A_CUM: 17957, D_CUM: -981, },
      { CDATE: '2024-07-19', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 443, A_WK: 500, D_WK: 56,   P_CUM: 19381, A_CUM: 18457, D_CUM: -925, },
      { CDATE: '2024-07-26', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 435, A_WK: 503, D_WK: 69,   P_CUM: 19816, A_CUM: 18960, D_CUM: -856, },
      { CDATE: '2024-08-02', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 423, A_WK: 351, D_WK: -72,  P_CUM: 20239, A_CUM: 19312, D_CUM: -928, },
      { CDATE: '2024-08-09', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 274, A_WK: 447, D_WK: 173,  P_CUM: 20514, A_CUM: 19759, D_CUM: -755, },
      { CDATE: '2024-08-16', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 424, A_WK: 482, D_WK: 58,   P_CUM: 20938, A_CUM: 20241, D_CUM: -697, },
      { CDATE: '2024-08-23', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 424, A_WK: 505, D_WK: 81,   P_CUM: 21362, A_CUM: 20745, D_CUM: -616, },
      { CDATE: '2024-08-30', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 412, A_WK: 516, D_WK: 105,  P_CUM: 21774, A_CUM: 21262, D_CUM: -512, },
      { CDATE: '2024-09-06', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 412, A_WK: 480, D_WK: 68,   P_CUM: 22185, A_CUM: 21742, D_CUM: -444, },
      { CDATE: '2024-09-13', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 410, A_WK: 509, D_WK: 99,   P_CUM: 22596, A_CUM: 22251, D_CUM: -345, },
      { CDATE: '2024-09-20', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 398, A_WK: 486, D_WK: 88,   P_CUM: 22994, A_CUM: 22737, D_CUM: -257, },
      { CDATE: '2024-09-27', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 399, A_WK: 505, D_WK: 107,  P_CUM: 23393, A_CUM: 23243, D_CUM: -150, },
      { CDATE: '2024-10-04', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 376, A_WK: 497, D_WK: 121,  P_CUM: 23768, A_CUM: 23740, D_CUM: -29, },
      { CDATE: '2024-10-11', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 375, A_WK: 488, D_WK: 114,  P_CUM: 24143, A_CUM: 24228, D_CUM: 85, },
      { CDATE: '2024-10-18', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 374, A_WK: 469, D_WK: 95,   P_CUM: 24517, A_CUM: 24697, D_CUM: 180, },
      { CDATE: '2024-10-25', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 357, A_WK: 498, D_WK: 141,  P_CUM: 24874, A_CUM: 25195, D_CUM: 321, },
      { CDATE: '2024-11-01', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 358, A_WK: 508, D_WK: 150,  P_CUM: 25232, A_CUM: 25703, D_CUM: 471, },
      { CDATE: '2024-11-08', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 342, A_WK: 476, D_WK: 134,  P_CUM: 25574, A_CUM: 26179, D_CUM: 605, },
      { CDATE: '2024-11-15', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 487, A_WK: 508, D_WK: 21,   P_CUM: 26061, A_CUM: 26687, D_CUM: 626, },
      { CDATE: '2024-11-22', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 510, A_WK: 517, D_WK: 7,    P_CUM: 26571, A_CUM: 27204, D_CUM: 633, },
      { CDATE: '2024-11-29', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 467, A_WK: 428, D_WK: -39,  P_CUM: 27037, A_CUM: 27632, D_CUM: 594, },
      { CDATE: '2024-12-06', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 480, A_WK: 484, D_WK: 4,    P_CUM: 27518, A_CUM: 28116, D_CUM: 598, },
      { CDATE: '2024-12-13', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 471, A_WK: 475, D_WK: 4,    P_CUM: 27989, A_CUM: 28591, D_CUM: 602, },
      { CDATE: '2024-12-20', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 456, A_WK: 475, D_WK: 19,   P_CUM: 28444, A_CUM: 29066, D_CUM: 621, },
      { CDATE: '2024-12-27', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 381, A_WK: 404, D_WK: 23,   P_CUM: 28825, A_CUM: 29470, D_CUM: 644, },
      { CDATE: '2025-01-03', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 412, A_WK: 0,   D_WK: 0,    P_CUM: 29237, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 479, A_WK: 0,   D_WK: 0,    P_CUM: 29716, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 480, A_WK: 0,   D_WK: 0,    P_CUM: 30196, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 458, A_WK: 0,   D_WK: 0,    P_CUM: 30654, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 507, A_WK: 0,   D_WK: 0,    P_CUM: 31161, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 446, A_WK: 0,   D_WK: 0,    P_CUM: 31606, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 514, A_WK: 0,   D_WK: 0,    P_CUM: 32120, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 550, A_WK: 0,   D_WK: 0,    P_CUM: 32671, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 551, A_WK: 0,   D_WK: 0,    P_CUM: 33222, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 560, A_WK: 0,   D_WK: 0,    P_CUM: 33782, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 565, A_WK: 0,   D_WK: 0,    P_CUM: 34347, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 514, A_WK: 0,   D_WK: 0,    P_CUM: 34860, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 621, A_WK: 0,   D_WK: 0,    P_CUM: 35481, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 561, A_WK: 0,   D_WK: 0,    P_CUM: 36042, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 474, A_WK: 0,   D_WK: 0,    P_CUM: 36516, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 444, A_WK: 0,   D_WK: 0,    P_CUM: 36960, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 415, A_WK: 0,   D_WK: 0,    P_CUM: 37375, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 629, A_WK: 0,   D_WK: 0,    P_CUM: 38004, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 343, A_WK: 0,   D_WK: 0,    P_CUM: 38347, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 490, A_WK: 0,   D_WK: 0,    P_CUM: 38837, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 386, A_WK: 0,   D_WK: 0,    P_CUM: 39223, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Engineering', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 45351, P_WK: 365, A_WK: 0,   D_WK: 0,    P_CUM: 39588, A_CUM: 29470, D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 2,   A_WK: 0,   D_WK: -2,   P_CUM: 2,     A_CUM: 0,     D_CUM: -2, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 28,  A_WK: 0,   D_WK: -28,  P_CUM: 31,    A_CUM: 0,     D_CUM: -31, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 48,  A_WK: 17,  D_WK: -32,  P_CUM: 79,    A_CUM: 17,    D_CUM: -62, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 59,  A_WK: 46,  D_WK: -13,  P_CUM: 138,   A_CUM: 63,    D_CUM: -75, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 62,  A_WK: 71,  D_WK: 9,    P_CUM: 199,   A_CUM: 134,   D_CUM: -66, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 78,  A_WK: 112, D_WK: 33,   P_CUM: 278,   A_CUM: 245,   D_CUM: -33, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 92,  A_WK: 126, D_WK: 34,   P_CUM: 369,   A_CUM: 371,   D_CUM: 2, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 99,  A_WK: 171, D_WK: 73,   P_CUM: 468,   A_CUM: 542,   D_CUM: 74, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 76,  A_WK: 177, D_WK: 101,  P_CUM: 544,   A_CUM: 718,   D_CUM: 175, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 76,  A_WK: 0,   D_WK: 0,    P_CUM: 619,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 90,  A_WK: 0,   D_WK: 0,    P_CUM: 709,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 110, A_WK: 0,   D_WK: 0,    P_CUM: 820,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 151, A_WK: 0,   D_WK: 0,    P_CUM: 970,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 68,  A_WK: 0,   D_WK: 0,    P_CUM: 1039,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 188, A_WK: 0,   D_WK: 0,    P_CUM: 1226,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 218, A_WK: 0,   D_WK: 0,    P_CUM: 1444,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 251, A_WK: 0,   D_WK: 0,    P_CUM: 1694,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 346, A_WK: 0,   D_WK: 0,    P_CUM: 2040,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 436, A_WK: 0,   D_WK: 0,    P_CUM: 2476,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 433, A_WK: 0,   D_WK: 0,    P_CUM: 2909,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 468, A_WK: 0,   D_WK: 0,    P_CUM: 3376,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 480, A_WK: 0,   D_WK: 0,    P_CUM: 3856,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 470, A_WK: 0,   D_WK: 0,    P_CUM: 4327,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 531, A_WK: 0,   D_WK: 0,    P_CUM: 4857,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 627, A_WK: 0,   D_WK: 0,    P_CUM: 5484,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 683, A_WK: 0,   D_WK: 0,    P_CUM: 6167,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 551, A_WK: 0,   D_WK: 0,    P_CUM: 6718,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 559, A_WK: 0,   D_WK: 0,    P_CUM: 7277,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 797, A_WK: 0,   D_WK: 0,    P_CUM: 8074,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 891, A_WK: 0,   D_WK: 0,    P_CUM: 8965,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'HHI',     TOTAL: 68490, P_WK: 916, A_WK: 0,   D_WK: 0,    P_CUM: 9880,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 2,   A_WK: 0,   D_WK: -2,   P_CUM: 2,     A_CUM: 0,     D_CUM: -2, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 28,  A_WK: 0,   D_WK: -28,  P_CUM: 31,    A_CUM: 0,     D_CUM: -31, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 48,  A_WK: 17,  D_WK: -32,  P_CUM: 79,    A_CUM: 17,    D_CUM: -62, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 59,  A_WK: 46,  D_WK: -13,  P_CUM: 138,   A_CUM: 63,    D_CUM: -75, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 62,  A_WK: 71,  D_WK: 9,    P_CUM: 199,   A_CUM: 134,   D_CUM: -66, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 78,  A_WK: 112, D_WK: 33,   P_CUM: 278,   A_CUM: 245,   D_CUM: -33, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 92,  A_WK: 126, D_WK: 34,   P_CUM: 369,   A_CUM: 371,   D_CUM: 2, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 99,  A_WK: 171, D_WK: 73,   P_CUM: 468,   A_CUM: 542,   D_CUM: 74, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 76,  A_WK: 177, D_WK: 101,  P_CUM: 544,   A_CUM: 718,   D_CUM: 175, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 76,  A_WK: 0,   D_WK: 0,    P_CUM: 619,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 90,  A_WK: 0,   D_WK: 0,    P_CUM: 709,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 110, A_WK: 0,   D_WK: 0,    P_CUM: 820,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 151, A_WK: 0,   D_WK: 0,    P_CUM: 970,   A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 68,  A_WK: 0,   D_WK: 0,    P_CUM: 1039,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 188, A_WK: 0,   D_WK: 0,    P_CUM: 1226,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 218, A_WK: 0,   D_WK: 0,    P_CUM: 1444,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 251, A_WK: 0,   D_WK: 0,    P_CUM: 1694,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 346, A_WK: 0,   D_WK: 0,    P_CUM: 2040,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 436, A_WK: 0,   D_WK: 0,    P_CUM: 2476,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 433, A_WK: 0,   D_WK: 0,    P_CUM: 2909,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 468, A_WK: 0,   D_WK: 0,    P_CUM: 3376,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 480, A_WK: 0,   D_WK: 0,    P_CUM: 3856,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 470, A_WK: 0,   D_WK: 0,    P_CUM: 4327,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 531, A_WK: 0,   D_WK: 0,    P_CUM: 4857,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 627, A_WK: 0,   D_WK: 0,    P_CUM: 5484,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 683, A_WK: 0,   D_WK: 0,    P_CUM: 6167,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 551, A_WK: 0,   D_WK: 0,    P_CUM: 6718,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 559, A_WK: 0,   D_WK: 0,    P_CUM: 7277,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 797, A_WK: 0,   D_WK: 0,    P_CUM: 8074,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 891, A_WK: 0,   D_WK: 0,    P_CUM: 8965,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'HHI',            SUBCONT: 'OVERALL', TOTAL: 68490, P_WK: 916, A_WK: 0,   D_WK: 0,    P_CUM: 9880,  A_CUM: 718,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 20,  D_WK: 20,   P_CUM: 0,     A_CUM: 20,    D_CUM: 20, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 30,  D_WK: 30,   P_CUM: 0,     A_CUM: 50,    D_CUM: 50, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 30,  D_WK: 30,   P_CUM: 0,     A_CUM: 80,    D_CUM: 80, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 30,  D_WK: 30,   P_CUM: 0,     A_CUM: 110,   D_CUM: 110, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 30,  D_WK: 30,   P_CUM: 0,     A_CUM: 140,   D_CUM: 140, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 35,  D_WK: 35,   P_CUM: 0,     A_CUM: 175,   D_CUM: 175, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 27,  D_WK: 27,   P_CUM: 0,     A_CUM: 202,   D_CUM: 202, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 26,  D_WK: 26,   P_CUM: 0,     A_CUM: 228,   D_CUM: 228, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 21,  D_WK: 21,   P_CUM: 0,     A_CUM: 249,   D_CUM: 249, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 249,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 10,  D_WK: 10,   P_CUM: 0,     A_CUM: 10,    D_CUM: 10, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 10,  D_WK: 10,   P_CUM: 0,     A_CUM: 20,    D_CUM: 20, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 12,  D_WK: 12,   P_CUM: 0,     A_CUM: 32,    D_CUM: 32, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 15,  D_WK: 15,   P_CUM: 0,     A_CUM: 47,    D_CUM: 47, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 20,  D_WK: 20,   P_CUM: 0,     A_CUM: 67,    D_CUM: 67, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 20,  D_WK: 20,   P_CUM: 0,     A_CUM: 87,    D_CUM: 87, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 20,  D_WK: 20,   P_CUM: 0,     A_CUM: 107,   D_CUM: 107, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 20,  D_WK: 20,   P_CUM: 0,     A_CUM: 127,   D_CUM: 127, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 15,  D_WK: 15,   P_CUM: 0,     A_CUM: 142,   D_CUM: 142, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'Sung-II', TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 142,   D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 3,   A_WK: 0,   D_WK: -3,   P_CUM: 3,     A_CUM: 0,     D_CUM: -3, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 12,  A_WK: 9,   D_WK: -3,   P_CUM: 15,    A_CUM: 9,     D_CUM: -6, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 33,  A_WK: 65,  D_WK: 32,   P_CUM: 48,    A_CUM: 74,    D_CUM: 26, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 58,  A_WK: 134, D_WK: 76,   P_CUM: 106,   A_CUM: 208,   D_CUM: 102, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 111, A_WK: 269, D_WK: 157,  P_CUM: 217,   A_CUM: 477,   D_CUM: 260, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 190, A_WK: 394, D_WK: 204,  P_CUM: 407,   A_CUM: 871,   D_CUM: 464, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 298, A_WK: 395, D_WK: 97,   P_CUM: 705,   A_CUM: 1266,  D_CUM: 561, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 353, A_WK: 400, D_WK: 47,   P_CUM: 1058,  A_CUM: 1666,  D_CUM: 608, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 388, A_WK: 0,   D_WK: 0,    P_CUM: 1446,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 579, A_WK: 0,   D_WK: 0,    P_CUM: 2025,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 691, A_WK: 0,   D_WK: 0,    P_CUM: 2716,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 768, A_WK: 0,   D_WK: 0,    P_CUM: 3484,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 136, A_WK: 0,   D_WK: 0,    P_CUM: 3620,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 396, A_WK: 0,   D_WK: 0,    P_CUM: 4016,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 779, A_WK: 0,   D_WK: 0,    P_CUM: 4795,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 731, A_WK: 0,   D_WK: 0,    P_CUM: 5526,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 628, A_WK: 0,   D_WK: 0,    P_CUM: 6154,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 526, A_WK: 0,   D_WK: 0,    P_CUM: 6680,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 459, A_WK: 0,   D_WK: 0,    P_CUM: 7139,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 559, A_WK: 0,   D_WK: 0,    P_CUM: 7698,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 563, A_WK: 0,   D_WK: 0,    P_CUM: 8261,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 494, A_WK: 0,   D_WK: 0,    P_CUM: 8755,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 928, A_WK: 0,   D_WK: 0,    P_CUM: 9683,  A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 976, A_WK: 0,   D_WK: 0,    P_CUM: 10659, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 715, A_WK: 0,   D_WK: 0,    P_CUM: 11373, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 952, A_WK: 0,   D_WK: 0,    P_CUM: 12325, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 1003, A_WK: 0,   D_WK: 0,    P_CUM: 13328, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 839, A_WK: 0,   D_WK: 0,    P_CUM: 14167, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 759, A_WK: 0,   D_WK: 0,    P_CUM: 14926, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'COSCO',   TOTAL: 23768, P_WK: 720, A_WK: 0,   D_WK: 0,    P_CUM: 15646, A_CUM: 1666,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 0,   A_WK: 30,  D_WK: 30,   P_CUM: 0,     A_CUM: 30,    D_CUM: 30, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 3,   A_WK: 40,  D_WK: 37,   P_CUM: 3,     A_CUM: 70,    D_CUM: 67, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 12,  A_WK: 51,  D_WK: 40,   P_CUM: 15,    A_CUM: 121,   D_CUM: 106, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 33,  A_WK: 110, D_WK: 77,   P_CUM: 48,    A_CUM: 231,   D_CUM: 183, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 58,  A_WK: 184, D_WK: 126,  P_CUM: 106,   A_CUM: 415,   D_CUM: 309, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 111, A_WK: 324, D_WK: 213,  P_CUM: 217,   A_CUM: 739,   D_CUM: 522, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 190, A_WK: 441, D_WK: 251,  P_CUM: 407,   A_CUM: 1180,  D_CUM: 773, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 298, A_WK: 440, D_WK: 142,  P_CUM: 705,   A_CUM: 1621,  D_CUM: 915, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 353, A_WK: 436, D_WK: 83,   P_CUM: 1058,  A_CUM: 2056,  D_CUM: 998, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 388, A_WK: 0,   D_WK: 0,    P_CUM: 1446,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 579, A_WK: 0,   D_WK: 0,    P_CUM: 2025,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 691, A_WK: 0,   D_WK: 0,    P_CUM: 2716,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 768, A_WK: 0,   D_WK: 0,    P_CUM: 3484,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 136, A_WK: 0,   D_WK: 0,    P_CUM: 3620,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 396, A_WK: 0,   D_WK: 0,    P_CUM: 4016,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 779, A_WK: 0,   D_WK: 0,    P_CUM: 4795,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 731, A_WK: 0,   D_WK: 0,    P_CUM: 5526,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 628, A_WK: 0,   D_WK: 0,    P_CUM: 6154,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 526, A_WK: 0,   D_WK: 0,    P_CUM: 6680,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 459, A_WK: 0,   D_WK: 0,    P_CUM: 7139,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 559, A_WK: 0,   D_WK: 0,    P_CUM: 7698,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 563, A_WK: 0,   D_WK: 0,    P_CUM: 8261,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 494, A_WK: 0,   D_WK: 0,    P_CUM: 8755,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 928, A_WK: 0,   D_WK: 0,    P_CUM: 9683,  A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 976, A_WK: 0,   D_WK: 0,    P_CUM: 10659, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 715, A_WK: 0,   D_WK: 0,    P_CUM: 11373, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 952, A_WK: 0,   D_WK: 0,    P_CUM: 12325, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 1003, A_WK: 0,   D_WK: 0,    P_CUM: 13328, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 839, A_WK: 0,   D_WK: 0,    P_CUM: 14167, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 759, A_WK: 0,   D_WK: 0,    P_CUM: 14926, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'Subcontractor',  SUBCONT: 'OVERALL', TOTAL: 23768, P_WK: 720, A_WK: 0,   D_WK: 0,    P_CUM: 15646, A_CUM: 2056,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 2,   A_WK: 30,  D_WK: 28,   P_CUM: 2,     A_CUM: 30,    D_CUM: 28, },
      { CDATE: '2024-11-08', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 32,  A_WK: 40,  D_WK: 8,    P_CUM: 34,    A_CUM: 70,    D_CUM: 36, },
      { CDATE: '2024-11-15', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 60,  A_WK: 68,  D_WK: 8,    P_CUM: 94,    A_CUM: 138,   D_CUM: 44, },
      { CDATE: '2024-11-22', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 92,  A_WK: 156, D_WK: 65,   P_CUM: 186,   A_CUM: 294,   D_CUM: 109, },
      { CDATE: '2024-11-29', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 120, A_WK: 255, D_WK: 135,  P_CUM: 305,   A_CUM: 549,   D_CUM: 244, },
      { CDATE: '2024-12-06', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 190, A_WK: 435, D_WK: 246,  P_CUM: 495,   A_CUM: 984,   D_CUM: 489, },
      { CDATE: '2024-12-13', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 282, A_WK: 567, D_WK: 285,  P_CUM: 776,   A_CUM: 1551,  D_CUM: 775, },
      { CDATE: '2024-12-20', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 397, A_WK: 611, D_WK: 215,  P_CUM: 1173,  A_CUM: 2162,  D_CUM: 990, },
      { CDATE: '2024-12-27', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 429, A_WK: 612, D_WK: 184,  P_CUM: 1602,  A_CUM: 2775,  D_CUM: 1173, },
      { CDATE: '2025-01-03', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 463, A_WK: 0,   D_WK: 0,    P_CUM: 2065,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 670, A_WK: 0,   D_WK: 0,    P_CUM: 2735,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 801, A_WK: 0,   D_WK: 0,    P_CUM: 3536,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 919, A_WK: 0,   D_WK: 0,    P_CUM: 4454,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 204, A_WK: 0,   D_WK: 0,    P_CUM: 4658,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 583, A_WK: 0,   D_WK: 0,    P_CUM: 5242,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 997, A_WK: 0,   D_WK: 0,    P_CUM: 6239,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 982, A_WK: 0,   D_WK: 0,    P_CUM: 7220,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 974, A_WK: 0,   D_WK: 0,    P_CUM: 8194,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 962, A_WK: 0,   D_WK: 0,    P_CUM: 9156,  A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 892, A_WK: 0,   D_WK: 0,    P_CUM: 10047, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1027, A_WK: 0,   D_WK: 0,    P_CUM: 11074, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1043, A_WK: 0,   D_WK: 0,    P_CUM: 12117, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 965, A_WK: 0,   D_WK: 0,    P_CUM: 13082, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1459, A_WK: 0,   D_WK: 0,    P_CUM: 14540, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1603, A_WK: 0,   D_WK: 0,    P_CUM: 16143, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1397, A_WK: 0,   D_WK: 0,    P_CUM: 17540, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1503, A_WK: 0,   D_WK: 0,    P_CUM: 19043, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1562, A_WK: 0,   D_WK: 0,    P_CUM: 20605, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1637, A_WK: 0,   D_WK: 0,    P_CUM: 22241, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1649, A_WK: 0,   D_WK: 0,    P_CUM: 23891, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Construction', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 92258, P_WK: 1636, A_WK: 0,   D_WK: 0,    P_CUM: 25527, A_CUM: 2775,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-15', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-22', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-06', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-13', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-20', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-03', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',     TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-15', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-22', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-06', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-13', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-20', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-03', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Commissioning', GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-15', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-22', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-06', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-13', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-20', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-03', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'OVERALL', TOTAL: 3200,  P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 30,  A_WK: 47,  D_WK: 17,   P_CUM: 1267,  A_CUM: 1479,  D_CUM: 212, },
      { CDATE: '2024-06-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 46,  D_WK: 6,    P_CUM: 1307,  A_CUM: 1525,  D_CUM: 218, },
      { CDATE: '2024-06-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 46,  D_WK: 6,    P_CUM: 1347,  A_CUM: 1571,  D_CUM: 224, },
      { CDATE: '2024-06-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1387,  A_CUM: 1616,  D_CUM: 229, },
      { CDATE: '2024-07-05', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1427,  A_CUM: 1661,  D_CUM: 234, },
      { CDATE: '2024-07-12', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1467,  A_CUM: 1709,  D_CUM: 242, },
      { CDATE: '2024-07-19', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 49,  D_WK: 9,    P_CUM: 1507,  A_CUM: 1758,  D_CUM: 251, },
      { CDATE: '2024-07-26', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1547,  A_CUM: 1806,  D_CUM: 259, },
      { CDATE: '2024-08-02', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 28,  D_WK: -12,  P_CUM: 1587,  A_CUM: 1834,  D_CUM: 247, },
      { CDATE: '2024-08-09', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 33,  D_WK: -7,   P_CUM: 1627,  A_CUM: 1867,  D_CUM: 240, },
      { CDATE: '2024-08-16', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 43,  D_WK: 3,    P_CUM: 1667,  A_CUM: 1910,  D_CUM: 243, },
      { CDATE: '2024-08-23', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 1707,  A_CUM: 1960,  D_CUM: 253, },
      { CDATE: '2024-08-30', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1747,  A_CUM: 2007,  D_CUM: 260, },
      { CDATE: '2024-09-06', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1787,  A_CUM: 2054,  D_CUM: 267, },
      { CDATE: '2024-09-13', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1827,  A_CUM: 2099,  D_CUM: 272, },
      { CDATE: '2024-09-20', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1867,  A_CUM: 2146,  D_CUM: 279, },
      { CDATE: '2024-09-27', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1907,  A_CUM: 2194,  D_CUM: 287, },
      { CDATE: '2024-10-04', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1947,  A_CUM: 2242,  D_CUM: 295, },
      { CDATE: '2024-10-11', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1987,  A_CUM: 2290,  D_CUM: 303, },
      { CDATE: '2024-10-18', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 49,  D_WK: 9,    P_CUM: 2027,  A_CUM: 2339,  D_CUM: 312, },
      { CDATE: '2024-10-25', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 2067,  A_CUM: 2387,  D_CUM: 320, },
      { CDATE: '2024-11-01', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2107,  A_CUM: 2437,  D_CUM: 330, },
      { CDATE: '2024-11-08', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2147,  A_CUM: 2487,  D_CUM: 340, },
      { CDATE: '2024-11-15', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2187,  A_CUM: 2537,  D_CUM: 350, },
      { CDATE: '2024-11-22', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2227,  A_CUM: 2587,  D_CUM: 360, },
      { CDATE: '2024-11-29', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2267,  A_CUM: 2637,  D_CUM: 370, },
      { CDATE: '2024-12-06', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2307,  A_CUM: 2687,  D_CUM: 380, },
      { CDATE: '2024-12-13', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2357,  A_CUM: 2737,  D_CUM: 380, },
      { CDATE: '2024-12-20', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2407,  A_CUM: 2787,  D_CUM: 380, },
      { CDATE: '2024-12-27', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2457,  A_CUM: 2837,  D_CUM: 380, },
      { CDATE: '2025-01-03', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2507,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2557,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2607,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2657,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2707,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2757,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2807,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2857,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2907,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2957,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3007,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3057,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3107,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3157,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3207,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3257,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3307,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3357,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3407,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3457,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3507,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'HHI',     TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3557,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 30,  A_WK: 47,  D_WK: 17,   P_CUM: 1267,  A_CUM: 1479,  D_CUM: 212, },
      { CDATE: '2024-06-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 46,  D_WK: 6,    P_CUM: 1307,  A_CUM: 1525,  D_CUM: 218, },
      { CDATE: '2024-06-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 46,  D_WK: 6,    P_CUM: 1347,  A_CUM: 1571,  D_CUM: 224, },
      { CDATE: '2024-06-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1387,  A_CUM: 1616,  D_CUM: 229, },
      { CDATE: '2024-07-05', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1427,  A_CUM: 1661,  D_CUM: 234, },
      { CDATE: '2024-07-12', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1467,  A_CUM: 1709,  D_CUM: 242, },
      { CDATE: '2024-07-19', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 49,  D_WK: 9,    P_CUM: 1507,  A_CUM: 1758,  D_CUM: 251, },
      { CDATE: '2024-07-26', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1547,  A_CUM: 1806,  D_CUM: 259, },
      { CDATE: '2024-08-02', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 28,  D_WK: -12,  P_CUM: 1587,  A_CUM: 1834,  D_CUM: 247, },
      { CDATE: '2024-08-09', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 33,  D_WK: -7,   P_CUM: 1627,  A_CUM: 1867,  D_CUM: 240, },
      { CDATE: '2024-08-16', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 43,  D_WK: 3,    P_CUM: 1667,  A_CUM: 1910,  D_CUM: 243, },
      { CDATE: '2024-08-23', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 1707,  A_CUM: 1960,  D_CUM: 253, },
      { CDATE: '2024-08-30', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1747,  A_CUM: 2007,  D_CUM: 260, },
      { CDATE: '2024-09-06', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1787,  A_CUM: 2054,  D_CUM: 267, },
      { CDATE: '2024-09-13', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1827,  A_CUM: 2099,  D_CUM: 272, },
      { CDATE: '2024-09-20', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1867,  A_CUM: 2146,  D_CUM: 279, },
      { CDATE: '2024-09-27', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1907,  A_CUM: 2194,  D_CUM: 287, },
      { CDATE: '2024-10-04', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1947,  A_CUM: 2242,  D_CUM: 295, },
      { CDATE: '2024-10-11', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1987,  A_CUM: 2290,  D_CUM: 303, },
      { CDATE: '2024-10-18', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 49,  D_WK: 9,    P_CUM: 2027,  A_CUM: 2339,  D_CUM: 312, },
      { CDATE: '2024-10-25', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 2067,  A_CUM: 2387,  D_CUM: 320, },
      { CDATE: '2024-11-01', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2107,  A_CUM: 2437,  D_CUM: 330, },
      { CDATE: '2024-11-08', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2147,  A_CUM: 2487,  D_CUM: 340, },
      { CDATE: '2024-11-15', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2187,  A_CUM: 2537,  D_CUM: 350, },
      { CDATE: '2024-11-22', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2227,  A_CUM: 2587,  D_CUM: 360, },
      { CDATE: '2024-11-29', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2267,  A_CUM: 2637,  D_CUM: 370, },
      { CDATE: '2024-12-06', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2307,  A_CUM: 2687,  D_CUM: 380, },
      { CDATE: '2024-12-13', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2357,  A_CUM: 2737,  D_CUM: 380, },
      { CDATE: '2024-12-20', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2407,  A_CUM: 2787,  D_CUM: 380, },
      { CDATE: '2024-12-27', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2457,  A_CUM: 2837,  D_CUM: 380, },
      { CDATE: '2025-01-03', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2507,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2557,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2607,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2657,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2707,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2757,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2807,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2857,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2907,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2957,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3007,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3057,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3107,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3157,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3207,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3257,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3307,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3357,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3407,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3457,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3507,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3557,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-05', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-12', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-19', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-07-26', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-02', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-09', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-16', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-23', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-08-30', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-06', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-13', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-20', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-09-27', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-04', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-11', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-18', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-10-25', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-01', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-08', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-15', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-22', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-11-29', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-06', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-13', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-20', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-12-27', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-03', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Management',  GROUP: 'Management',     SUBCONT: 'Etc.',    TOTAL: 0,     P_WK: 0,   A_WK: 0,   D_WK: 0,    P_CUM: 0,     A_CUM: 0,     D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 30,  A_WK: 47,  D_WK: 17,   P_CUM: 1267,  A_CUM: 1479,  D_CUM: 212, },
      { CDATE: '2024-06-14', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 46,  D_WK: 6,    P_CUM: 1307,  A_CUM: 1525,  D_CUM: 218, },
      { CDATE: '2024-06-21', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 46,  D_WK: 6,    P_CUM: 1347,  A_CUM: 1571,  D_CUM: 224, },
      { CDATE: '2024-06-28', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1387,  A_CUM: 1616,  D_CUM: 229, },
      { CDATE: '2024-07-05', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1427,  A_CUM: 1661,  D_CUM: 234, },
      { CDATE: '2024-07-12', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1467,  A_CUM: 1709,  D_CUM: 242, },
      { CDATE: '2024-07-19', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 49,  D_WK: 9,    P_CUM: 1507,  A_CUM: 1758,  D_CUM: 251, },
      { CDATE: '2024-07-26', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1547,  A_CUM: 1806,  D_CUM: 259, },
      { CDATE: '2024-08-02', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 28,  D_WK: -12,  P_CUM: 1587,  A_CUM: 1834,  D_CUM: 247, },
      { CDATE: '2024-08-09', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 33,  D_WK: -7,   P_CUM: 1627,  A_CUM: 1867,  D_CUM: 240, },
      { CDATE: '2024-08-16', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 43,  D_WK: 3,    P_CUM: 1667,  A_CUM: 1910,  D_CUM: 243, },
      { CDATE: '2024-08-23', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 1707,  A_CUM: 1960,  D_CUM: 253, },
      { CDATE: '2024-08-30', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1747,  A_CUM: 2007,  D_CUM: 260, },
      { CDATE: '2024-09-06', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1787,  A_CUM: 2054,  D_CUM: 267, },
      { CDATE: '2024-09-13', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 45,  D_WK: 5,    P_CUM: 1827,  A_CUM: 2099,  D_CUM: 272, },
      { CDATE: '2024-09-20', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 47,  D_WK: 7,    P_CUM: 1867,  A_CUM: 2146,  D_CUM: 279, },
      { CDATE: '2024-09-27', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1907,  A_CUM: 2194,  D_CUM: 287, },
      { CDATE: '2024-10-04', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1947,  A_CUM: 2242,  D_CUM: 295, },
      { CDATE: '2024-10-11', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 1987,  A_CUM: 2290,  D_CUM: 303, },
      { CDATE: '2024-10-18', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 49,  D_WK: 9,    P_CUM: 2027,  A_CUM: 2339,  D_CUM: 312, },
      { CDATE: '2024-10-25', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 48,  D_WK: 8,    P_CUM: 2067,  A_CUM: 2387,  D_CUM: 320, },
      { CDATE: '2024-11-01', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2107,  A_CUM: 2437,  D_CUM: 330, },
      { CDATE: '2024-11-08', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2147,  A_CUM: 2487,  D_CUM: 340, },
      { CDATE: '2024-11-15', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2187,  A_CUM: 2537,  D_CUM: 350, },
      { CDATE: '2024-11-22', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2227,  A_CUM: 2587,  D_CUM: 360, },
      { CDATE: '2024-11-29', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2267,  A_CUM: 2637,  D_CUM: 370, },
      { CDATE: '2024-12-06', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 40,  A_WK: 50,  D_WK: 10,   P_CUM: 2307,  A_CUM: 2687,  D_CUM: 380, },
      { CDATE: '2024-12-13', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2357,  A_CUM: 2737,  D_CUM: 380, },
      { CDATE: '2024-12-20', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2407,  A_CUM: 2787,  D_CUM: 380, },
      { CDATE: '2024-12-27', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 50,  D_WK: 0,    P_CUM: 2457,  A_CUM: 2837,  D_CUM: 380, },
      { CDATE: '2025-01-03', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2507,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2557,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2607,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2657,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2707,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2757,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2807,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2857,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2907,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 2957,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3007,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3057,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3107,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3157,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3207,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3257,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3307,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3357,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3407,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3457,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3507,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'Management',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 7171,  P_WK: 50,  A_WK: 0,   D_WK: 0,    P_CUM: 3557,  A_CUM: 2837,  D_CUM: 0, },
      { CDATE: '2024-06-07', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 437, A_WK: 491, D_WK: 54,   P_CUM: 18155, A_CUM: 17092, D_CUM: -1063, },
      { CDATE: '2024-06-14', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 447, A_WK: 512, D_WK: 64,   P_CUM: 18603, A_CUM: 17604, D_CUM: -999, },
      { CDATE: '2024-06-21', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 447, A_WK: 539, D_WK: 91,   P_CUM: 19050, A_CUM: 18142, D_CUM: -907, },
      { CDATE: '2024-06-28', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 441, A_WK: 541, D_WK: 100,  P_CUM: 19491, A_CUM: 18683, D_CUM: -808, },
      { CDATE: '2024-07-05', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 430, A_WK: 472, D_WK: 41,   P_CUM: 19921, A_CUM: 19155, D_CUM: -766, },
      { CDATE: '2024-07-12', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 484, A_WK: 511, D_WK: 27,   P_CUM: 20405, A_CUM: 19666, D_CUM: -739, },
      { CDATE: '2024-07-19', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 483, A_WK: 549, D_WK: 66,   P_CUM: 20888, A_CUM: 20215, D_CUM: -674, },
      { CDATE: '2024-07-26', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 475, A_WK: 552, D_WK: 77,   P_CUM: 21363, A_CUM: 20766, D_CUM: -597, },
      { CDATE: '2024-08-02', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 463, A_WK: 379, D_WK: -84,  P_CUM: 21826, A_CUM: 21146, D_CUM: -681, },
      { CDATE: '2024-08-09', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 314, A_WK: 480, D_WK: 166,  P_CUM: 22141, A_CUM: 21626, D_CUM: -515, },
      { CDATE: '2024-08-16', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 464, A_WK: 525, D_WK: 61,   P_CUM: 22605, A_CUM: 22151, D_CUM: -454, },
      { CDATE: '2024-08-23', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 464, A_WK: 555, D_WK: 91,   P_CUM: 23069, A_CUM: 22705, D_CUM: -363, },
      { CDATE: '2024-08-30', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 452, A_WK: 563, D_WK: 112,  P_CUM: 23521, A_CUM: 23269, D_CUM: -252, },
      { CDATE: '2024-09-06', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 452, A_WK: 527, D_WK: 75,   P_CUM: 23972, A_CUM: 23796, D_CUM: -177, },
      { CDATE: '2024-09-13', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 450, A_WK: 554, D_WK: 104,  P_CUM: 24423, A_CUM: 24350, D_CUM: -73, },
      { CDATE: '2024-09-20', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 438, A_WK: 533, D_WK: 95,   P_CUM: 24861, A_CUM: 24883, D_CUM: 22, },
      { CDATE: '2024-09-27', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 439, A_WK: 553, D_WK: 115,  P_CUM: 25300, A_CUM: 25436, D_CUM: 137, },
      { CDATE: '2024-10-04', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 416, A_WK: 545, D_WK: 129,  P_CUM: 25715, A_CUM: 25982, D_CUM: 266, },
      { CDATE: '2024-10-11', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 415, A_WK: 536, D_WK: 122,  P_CUM: 26130, A_CUM: 26518, D_CUM: 388, },
      { CDATE: '2024-10-18', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 414, A_WK: 518, D_WK: 104,  P_CUM: 26544, A_CUM: 27036, D_CUM: 492, },
      { CDATE: '2024-10-25', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 397, A_WK: 546, D_WK: 149,  P_CUM: 26941, A_CUM: 27582, D_CUM: 641, },
      { CDATE: '2024-11-01', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 400, A_WK: 588, D_WK: 188,  P_CUM: 27342, A_CUM: 28170, D_CUM: 829, },
      { CDATE: '2024-11-08', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 413, A_WK: 566, D_WK: 152,  P_CUM: 27755, A_CUM: 28736, D_CUM: 981, },
      { CDATE: '2024-11-15', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 587, A_WK: 626, D_WK: 39,   P_CUM: 28342, A_CUM: 29362, D_CUM: 1020, },
      { CDATE: '2024-11-22', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 642, A_WK: 723, D_WK: 81,   P_CUM: 28983, A_CUM: 30085, D_CUM: 1101, },
      { CDATE: '2024-11-29', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 626, A_WK: 733, D_WK: 107,  P_CUM: 29610, A_CUM: 30818, D_CUM: 1208, },
      { CDATE: '2024-12-06', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 710, A_WK: 970, D_WK: 260,  P_CUM: 30319, A_CUM: 31787, D_CUM: 1468, },
      { CDATE: '2024-12-13', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 803, A_WK: 1092, D_WK: 289,  P_CUM: 31122, A_CUM: 32879, D_CUM: 1757, },
      { CDATE: '2024-12-20', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 902, A_WK: 1136, D_WK: 234,  P_CUM: 32024, A_CUM: 34015, D_CUM: 1991, },
      { CDATE: '2024-12-27', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 860, A_WK: 1066, D_WK: 207,  P_CUM: 32884, A_CUM: 35081, D_CUM: 2197, },
      { CDATE: '2025-01-03', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 926, A_WK: 0,   D_WK: 0,    P_CUM: 33809, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-01-10', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1199, A_WK: 0,   D_WK: 0,    P_CUM: 35008, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-01-17', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1331, A_WK: 0,   D_WK: 0,    P_CUM: 36339, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-01-24', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1427, A_WK: 0,   D_WK: 0,    P_CUM: 37765, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-01-31', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 761, A_WK: 0,   D_WK: 0,    P_CUM: 38526, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-02-07', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1079, A_WK: 0,   D_WK: 0,    P_CUM: 39605, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-02-14', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1561, A_WK: 0,   D_WK: 0,    P_CUM: 41166, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-02-21', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1582, A_WK: 0,   D_WK: 0,    P_CUM: 42748, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-02-28', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1575, A_WK: 0,   D_WK: 0,    P_CUM: 44323, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-03-07', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1571, A_WK: 0,   D_WK: 0,    P_CUM: 45894, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-03-14', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1507, A_WK: 0,   D_WK: 0,    P_CUM: 47401, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-03-21', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1590, A_WK: 0,   D_WK: 0,    P_CUM: 48991, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-03-28', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1714, A_WK: 0,   D_WK: 0,    P_CUM: 50705, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-04-04', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1576, A_WK: 0,   D_WK: 0,    P_CUM: 52281, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-04-11', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1982, A_WK: 0,   D_WK: 0,    P_CUM: 54263, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-04-18', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 2097, A_WK: 0,   D_WK: 0,    P_CUM: 56360, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-04-25', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1863, A_WK: 0,   D_WK: 0,    P_CUM: 58222, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-05-02', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 2182, A_WK: 0,   D_WK: 0,    P_CUM: 60405, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-05-09', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 1954, A_WK: 0,   D_WK: 0,    P_CUM: 62359, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-05-16', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 2176, A_WK: 0,   D_WK: 0,    P_CUM: 64535, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-05-23', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 2085, A_WK: 0,   D_WK: 0,    P_CUM: 66620, A_CUM: 35081, D_CUM: 0, },
      { CDATE: '2025-05-30', PHASE: 'OVERALL',     GROUP: 'OVERALL',        SUBCONT: 'OVERALL', TOTAL: 147979, P_WK: 2051, A_WK: 0,   D_WK: 0,    P_CUM: 68671, A_CUM: 35081, D_CUM: 0, },
    ],


    Queries: {
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-12-27',
          START_DATE  : '2024-06-07',
          END_DATE    : '2025-05-30',
        },
      ],

    },


}