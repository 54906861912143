
import icon from '../../../../../../includes/primitives/icons'

export default {
  data: () => ({

    rawData:        null,
    chartData:      null,

    canvasForms:    null,
    canvasTable:    null,
    canvasChart:    null,

    chart: {
      x: 60, 
      y: 70,
      width: 680,
      height: 340,

      bar: [
        {name: 'Plan wk.',     code: 'P_WK', type: 'PLAN',sColor: '#bcbcbc', opacity: .5},
        {name: 'Actual wk.',   code: 'A_WK', type: 'ACT', sColor: '#83D2F5', opacity: .9},
      ],

      line: [
        {name: 'Plan Cuml.',   code: 'P_CUM', type: 'PLAN',sColor: '#bcbcbc', sWidth: .5,},
        {name: 'Actual Cuml.', code: 'A_CUM', type: 'ACT', sColor: '#44A9DF', sWidth: 1.5},
      ],
    },

    timeline:     {
      month: [],
      year: [],
      scale: null,
      month_count: 0,
      monthWidth: 0,
    },

    icon:         icon.arrow,
    primary:      {},
    secoundary:   {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}