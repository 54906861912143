import * as d3 from 'd3'

export default {
  methods: {

    call_Overall(selection) {
      // let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // if(clickTimeout) return
        this.action_Overall_to_Area()
        // clickTimeout = true
      }) 
    },
 

    call_Area(selection, _area) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => { 
        if(clickTimeout) return
        
        this.current.area = _area
        this.gmx_removeExeFunc({
            remove: [this.model_Area, this.canvas_Area,],
            exeFunc: this.draw_Model_Erection,
            data: null
        })
        clickTimeout = true
      })
    },


    call_Erection_Block(selection, _d, _func) {
      let clickTimeout = false
      
      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', .5) // KeyPlan
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', 0) // KeyPlan
      })
      .on('click', () => {
        if(clickTimeout) return

        if (this.current.area == 'Hull') this.current.block = _d.BLK
        else this.current.pe = _d.BLK

        this.current.sn = _d.SN
        this.gmx_removeExeFunc({
          remove: [this.model_Erection, this.canvas_Erection,],
          exeFunc: _func,
          data: _d
        })
        clickTimeout = true
      })
    },

    
    call_2PE_Block(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // console.log(_d)
        if(clickTimeout) return

        this.current.pe = _d.AREA
        this.current.sn = _d.SN
        this.gmx_removeExeFunc({
          remove: [this.model_2PE, this.canvas_2PE,],
          exeFunc: this.draw_Model_Block,
          data: _d
        })
        clickTimeout = true
      })
    },


  }
}