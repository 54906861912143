import * as d3 from 'd3'

export default {
  methods: {

    bar_Summary(selection, data, st) {
      
      let progress = selection
      .append('g')
      .attr('transform', `translate(${st.x}, ${st.y})`)
      .attr('cursor', 'default')
      

      if (st.size == 'L') {
        progress
        .append('text')
        .attr('id', `TITLE_${st.idx}`)
        .attr('x', 0) .attr('y', -15)
        .attr('font-size', (st.size == 'L') ? 15 : 13).attr('fill', '#000')
        .text(data.MOD)

        progress
        .append('text')
        .attr('x', this.getNodeElValue(`#TITLE_${st.idx}`, 'width') + 3) .attr('y', -15)
        .attr('font-size', (st.size == 'L') ? 10 : 9).attr('fill', '#858585')
        .text(data.NAME)

        progress
        .append('text')
        .attr('x', st.bar) .attr('y', -13)
        .attr('font-size', 12).attr('fill', '#44A9DF').attr('text-anchor', 'end')
        .text(data.TOTAL)
        .attr('cursor', 'pointer')
        .call(this.link_Event, 'TOTAL', data,)

        this.status.forEach((d,i)=> {
          progress
          .append('text')
          .attr('x', -4) .attr('y', i*18)
          .attr('font-size', 10.5).attr('fill', '#333').attr('text-anchor', 'end')
          .text(d.name)

          progress
          .append('rect')
          .attr('x', 0) .attr('y', i*18 -10)
          .attr('width', st.bar).attr('height', 14).attr('fill', '#F4F5F5').attr('opacity', .5)

          let len = (data[d.code]/data.TOTAL) * st.bar
          progress
          .append('rect') // Actual Bar
          .attr('x', 0) .attr('y', i*18 -10)
          .attr('width', len).attr('height', 14).attr('fill', d.color)

          progress
          .append('text')
          .attr('x', len > (st.bar*.7) ? len - 2 : len + 2) .attr('y', (i*18))
          .attr('font-size', 10.5).attr('fill', '#333')
          .attr('text-anchor', len > (st.bar*.7) ? 'end' : 'start')
          .text(data[d.code])
          .attr('cursor', 'pointer')
          .call(this.link_Event, d.code, data,)
        })
      }

      if (st.size == 'M') {
        
        progress
        .append('text')
        .attr('id', `TITLE_${st.idx}`)
        .attr('x', 0) .attr('y', -15)
        .attr('font-size', (st.size == 'L') ? 15 : 13).attr('fill', '#000')
        .text(data.MOD)

        progress
        .append('text')
        .attr('x', this.getNodeElValue(`#TITLE_${st.idx}`, 'width') + 3) .attr('y', -15)
        .attr('font-size', (st.size == 'L') ? 10 : 9).attr('fill', '#858585')
        .text(data.NAME)

        progress
        .append('text')
        .attr('x', st.bar) .attr('y', -13)
        .attr('font-size', 12).attr('fill', '#44A9DF').attr('text-anchor', 'end')
        .text(data.TOTAL)
        .attr('cursor', 'pointer')
        .call(this.link_Event, 'TOTAL', data,)
        
        this.status.forEach((d,i)=> {
          progress
          .append('text')
          .attr('x', -3) .attr('y', i*16)
          .attr('font-size', (st.size == 'L') ? 10.5 : 10).attr('fill', '#333').attr('text-anchor', 'end')
          .text(d.name)

          progress
          .append('rect')
          .attr('x', 0) .attr('y', i*16 -10)
          .attr('width', st.bar).attr('height', 13).attr('fill', '#F4F5F5').attr('opacity', .5)

          let len = (data[d.code]/data.TOTAL) * st.bar
          progress
          .append('rect') // Actual Bar
          .attr('x', 0) .attr('y', i*16 -10)
          .attr('width', len).attr('height', 13).attr('fill', d.color)

          progress
          .append('text')
          .attr('x', len > (st.bar*.7) ? len - 2 : len + 2) .attr('y', (i*16))
          .attr('font-size', (st.size == 'L') ? 10.5 : 10).attr('fill', '#333')
          .attr('text-anchor', len > (st.bar*.7) ? 'end' : 'start')
          .text(data[d.code])
          .attr('cursor', 'pointer')
          .call(this.link_Event, d.code, data,)
        })
      }

      if (st.size == 'S') {
        
        progress
        .append('text')
        .attr('id', `TITLE_${st.idx}`)
        .attr('x', 0) .attr('y', -15)
        .attr('font-size', (st.size == 'L') ? 15 : 13).attr('fill', '#000')
        .text(data.NAME)

        progress
        .append('text')
        .attr('x', st.bar) .attr('y', -13)
        .attr('font-size', 11).attr('fill', '#44A9DF').attr('text-anchor', 'end')
        .text(data.TOTAL)
        .attr('cursor', 'pointer')
        .call(this.link_Event, 'TOTAL', data,)
        
        this.status.forEach((d,i)=> {
          progress
          .append('text')
          .attr('x', -3) .attr('y', i*16)
          .attr('font-size', (st.size == 'L') ? 10.5 : 10).attr('fill', '#333').attr('text-anchor', 'end')
          .text(d.name)

          progress
          .append('rect')
          .attr('x', 0) .attr('y', i*16 -10)
          .attr('width', st.bar).attr('height', 13).attr('fill', '#F4F5F5').attr('opacity', .5)

          let len = (data[d.code]/data.TOTAL) * st.bar
          progress
          .append('rect') // Actual Bar
          .attr('x', 0) .attr('y', i*16 -10)
          .attr('width', len).attr('height', 13).attr('fill', d.color)

          progress
          .append('text')
          .attr('x', len > (st.bar*.7) ? len - 2 : len + 2) .attr('y', (i*16))
          .attr('font-size', (st.size == 'L') ? 10.5 : 10).attr('fill', '#333')
          .attr('text-anchor', len > (st.bar*.7) ? 'end' : 'start')
          .text(data[d.code])
          .attr('cursor', 'pointer')
          .call(this.link_Event, d.code, data,)
        })
      }

    },



  }
}