
export default {
  data: () => ({

    localData: {
      JSON1: [
        { SN: '1',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node',            ID: 'WN11',    SVG: 'WN11',    BLK: 'WN112', YARD: 'HHI', },
        { SN: '2',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node',            ID: 'WN16',    SVG: 'WN16',    BLK: 'WN161', YARD: 'HHI', },
        { SN: '3',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node',            ID: 'WN21',    SVG: 'WN21',    BLK: 'WN212', YARD: 'HHI', },
        { SN: '4',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node',            ID: 'WN26',    SVG: 'WN26',    BLK: 'WN261', YARD: 'HHI', },
        { SN: '5',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node',            ID: 'EN11',    SVG: 'EN11',    BLK: 'EN112', YARD: 'HHI', },
        { SN: '6',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node',            ID: 'EN16',    SVG: 'EN16',    BLK: 'EN161', YARD: 'HHI', },
        { SN: '7',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node',            ID: 'EN21',    SVG: 'EN21',    BLK: 'EN212', YARD: 'HHI', },
        { SN: '8',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node',            ID: 'EN26',    SVG: 'EN26',    BLK: 'EN261', YARD: 'HHI', },
        { SN: '9',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-West',          ID: 'WN41',    SVG: 'WN41',    BLK: 'WN41',  YARD: 'HHI', },
        { SN: '10',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-West',          ID: 'WN42',    SVG: 'WN42',    BLK: 'WN42',  YARD: 'HHI', },
        { SN: '11',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-West',          ID: 'WN51',    SVG: 'WN51',    BLK: 'WN51',  YARD: 'HHI', },
        { SN: '12',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-West',          ID: 'WN52',    SVG: 'WN52',    BLK: 'WN52',  YARD: 'HHI', },
        { SN: '13',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-East',          ID: 'EN41',    SVG: 'EN41',    BLK: 'EN41',  YARD: 'HHI', },
        { SN: '14',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-East',          ID: 'EN42',    SVG: 'EN42',    BLK: 'EN42',  YARD: 'HHI', },
        { SN: '15',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-East',          ID: 'EN51',    SVG: 'EN51',    BLK: 'EN51',  YARD: 'HHI', },
        { SN: '16',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-East',          ID: 'EN52',    SVG: 'EN52',    BLK: 'EN52',  YARD: 'HHI', },
        { SN: '17',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'East Pontoon',        ID: 'EP11',    SVG: 'EP11',    BLK: 'EP112', YARD: 'HHI', },
        { SN: '18',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon',        ID: 'WP11',    SVG: 'WP11',    BLK: 'WP112', YARD: 'COSCO', },
        { SN: '19',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon',        ID: 'WPB1',    SVG: 'WPB1',    BLK: 'WPB1',  YARD: 'HHI', },
        { SN: '20',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'South Pontoon',       ID: 'SP11',    SVG: 'SP11',    BLK: 'SP112', YARD: 'COSCO', },
        { SN: '21',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon',       ID: 'NP11',    SVG: 'NP11',    BLK: 'NP112', YARD: 'COSCO', },
        { SN: '22',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon',       ID: 'NPB1',    SVG: 'NPB1',    BLK: 'NPB1',  YARD: 'HHI', },
        { SN: '23',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Lower Column',    ID: 'WC11',    SVG: 'WC11',    BLK: 'WC112', YARD: 'COSCO', },
        { SN: '24',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Middle Column',   ID: 'WC1K',    SVG: 'WC1K',    BLK: 'WC1K3', YARD: 'COSCO', },
        { SN: '25',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Middle Column',   ID: 'WC3A',    SVG: 'WC3A',    BLK: 'WC3A',  YARD: 'HHI', },
        { SN: '26',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WC1G',    SVG: 'WC1G',    BLK: 'WC1G2', YARD: 'HHI', },
        { SN: '27',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WCP1',    SVG: 'WCP1',    BLK: 'WCP1',  YARD: 'HHI', },
        { SN: '28',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WCP2',    SVG: 'WCP2',    BLK: 'WCP2',  YARD: 'HHI', },
        { SN: '29',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WCP3',    SVG: 'WCP3',    BLK: 'WCP3',  YARD: 'HHI', },
        { SN: '30',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WCP4',    SVG: 'WCP4',    BLK: 'WCP4',  YARD: 'HHI', },
        { SN: '31',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WCP5',    SVG: 'WCP5',    BLK: 'WCP5',  YARD: 'HHI', },
        { SN: '32',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    ID: 'WCP6',    SVG: 'WCP6',    BLK: 'WCP6',  YARD: 'HHI', },
        { SN: '33',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Lower Column',    ID: 'WC21',    SVG: 'WC21',    BLK: 'WC212', YARD: 'COSCO', },
        { SN: '34',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Middle Column',   ID: 'WC2K',    SVG: 'WC2K',    BLK: 'WC2K2', YARD: 'COSCO', },
        { SN: '35',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Middle Column',   ID: 'WC4A',    SVG: 'WC4A',    BLK: 'WC4A',  YARD: 'HHI', },
        { SN: '36',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column',    ID: 'WC2G',    SVG: 'WC2G',    BLK: 'WC2G2', YARD: 'HHI', },
        { SN: '37',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column',    ID: 'WCP8',    SVG: 'WCP8',    BLK: 'WCP8',  YARD: 'HHI', },
        { SN: '38',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column',    ID: 'WCP9',    SVG: 'WCP9',    BLK: 'WCP9',  YARD: 'HHI', },
        { SN: '39',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Lower Column',    ID: 'EC11',    SVG: 'EC11',    BLK: 'EC112', YARD: 'COSCO', },
        { SN: '40',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Middle Column',   ID: 'EC1K',    SVG: 'EC1K',    BLK: 'EC1K2', YARD: 'COSCO', },
        { SN: '41',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Middle Column',   ID: 'EC3A',    SVG: 'EC3A',    BLK: 'EC3A',  YARD: 'HHI', },
        { SN: '42',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column',    ID: 'EC1G',    SVG: 'EC1G',    BLK: 'EC1G2', YARD: 'HHI', },
        { SN: '43',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column',    ID: 'ECP1',    SVG: 'ECP1',    BLK: 'ECP1',  YARD: 'HHI', },
        { SN: '44',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column',    ID: 'ECP3',    SVG: 'ECP3',    BLK: 'ECP3',  YARD: 'HHI', },
        { SN: '45',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Lower Column',    ID: 'EC21',    SVG: 'EC21',    BLK: 'EC212', YARD: 'COSCO', },
        { SN: '46',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Middle Column',   ID: 'EC2K',    SVG: 'EC2K',    BLK: 'EC2K2', YARD: 'COSCO', },
        { SN: '47',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Middle Column',   ID: 'EC4A',    SVG: 'EC4A',    BLK: 'EC4A',  YARD: 'HHI', },
        { SN: '48',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Upper Column',    ID: 'EC2G',    SVG: 'EC2G',    BLK: 'EC2G2', YARD: 'HHI', },
        { SN: '49',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          ID: 'ECP4',    SVG: 'ECP4',    BLK: 'ECP4',  YARD: 'HHI', },
        { SN: '50',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          ID: 'ECP5',    SVG: 'ECP5',    BLK: 'ECP5',  YARD: 'HHI', },
        { SN: '51',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          ID: 'ECP6',    SVG: 'ECP6',    BLK: 'ECP6',  YARD: 'HHI', },
        { SN: '52',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          ID: 'ECP7',    SVG: 'ECP7',    BLK: 'ECP7',  YARD: 'HHI', },
        { SN: '53',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'East',                ID: 'EF21',    SVG: 'EF21',    BLK: 'EF21',  YARD: 'HHI', },
        { SN: '54',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'West',                ID: 'WF21',    SVG: 'WF21',    BLK: 'WF21',  YARD: 'HHI', },
        { SN: '55',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'South',               ID: 'SF21',    SVG: 'SF21',    BLK: 'SF21',  YARD: 'HHI', },
        { SN: '56',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'North',               ID: 'NF21',    SVG: 'NF21',    BLK: 'NF21',  YARD: 'HHI', },
        { SN: '57',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'North',               ID: 'NFP1',    SVG: 'NFP1',    BLK: 'NFP1',  YARD: 'HHI', },
        { SN: '58',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'Center',              ID: 'CF11',    SVG: 'CF11',    BLK: 'CF112', YARD: 'HHI', },
        { SN: '59',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'NW',                  ID: 'CC41',    SVG: 'CC41',    BLK: 'CC41',  YARD: 'HHI', },
        { SN: '60',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'SW',                  ID: 'CC42',    SVG: 'CC42',    BLK: 'CC42',  YARD: 'HHI', },
        { SN: '61',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'NE',                  ID: 'CC43',    SVG: 'CC43',    BLK: 'CC43',  YARD: 'HHI', },
        { SN: '62',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'SE',                  ID: 'CC44',    SVG: 'CC44',    BLK: 'CC44',  YARD: 'HHI', },
        { SN: '63',  AREA: 'HULL',    ZONE: 'RISER PIPE', LEVEL: 'NW',                  ID: 'RC41',    SVG: 'RC41',    BLK: 'RC41',  YARD: 'HHI', },
        { SN: '64',  AREA: 'HULL',    ZONE: 'RISER PIPE', LEVEL: 'SW',                  ID: 'RC42',    SVG: 'RC42',    BLK: 'RC42',  YARD: 'HHI', },
        { SN: '65',  AREA: 'HULL',    ZONE: 'SUMP PILE',  LEVEL: 'NE',                  ID: 'SC41',    SVG: 'SC41',    BLK: 'SC41',  YARD: 'HHI', },
        { SN: '66',  AREA: 'HULL',    ZONE: 'SUMP PILE',  LEVEL: 'SE',                  ID: 'SC42',    SVG: 'SC42',    BLK: 'SC42',  YARD: 'HHI', },
        { SN: '67',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck',     ID: 'ND11',    SVG: 'ND11',    BLK: 'ND112', YARD: 'HHI', },
        { SN: '68',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck',     ID: 'ND12',    SVG: 'ND12',    BLK: 'ND12',  YARD: 'HHI', },
        { SN: '69',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          ID: 'ND21',    SVG: 'ND21',    BLK: 'ND21',  YARD: 'HHI', },
        { SN: '70',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          ID: 'ND22',    SVG: 'ND22',    BLK: 'ND22',  YARD: 'HHI', },
        { SN: '71',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          ID: 'ND23',    SVG: 'ND23',    BLK: 'ND23',  YARD: 'HHI', },
        { SN: '72',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          ID: 'ND25',    SVG: 'ND25',    BLK: 'ND25',  YARD: 'HHI', },
        { SN: '73',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck',           ID: 'ND31',    SVG: 'ND31',    BLK: 'ND31',  YARD: 'HHI', },
        { SN: '74',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck',           ID: 'ND33',    SVG: 'ND33',    BLK: 'ND33',  YARD: 'HHI', },
        { SN: '75',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck',           ID: 'NX11',    SVG: 'NX11',    BLK: 'NX11',  YARD: 'HHI', },
        { SN: '76',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Compressor Platform', ID: 'KD11',    SVG: 'KD11',    BLK: 'KD11',  YARD: 'HHI', },
        { SN: '77',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck',     ID: 'CD11',    SVG: 'CD11',    BLK: 'CD11',  YARD: 'HHI', },
        { SN: '78',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck',     ID: 'CD13',    SVG: 'CD13',    BLK: 'CD13',  YARD: 'HHI', },
        { SN: '79',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD21',    SVG: 'CD21',    BLK: 'CD21',  YARD: 'HHI', },
        { SN: '80',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD22',    SVG: 'CD22',    BLK: 'CD22',  YARD: 'HHI', },
        { SN: '81',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD23',    SVG: 'CD23',    BLK: 'CD23',  YARD: 'HHI', },
        { SN: '82',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD24',    SVG: 'CD24',    BLK: 'CD24',  YARD: 'HHI', },
        { SN: '83',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD25',    SVG: 'CD25',    BLK: 'CD25',  YARD: 'HHI', },
        { SN: '84',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD26',    SVG: 'CD26',    BLK: 'CD26',  YARD: 'HHI', },
        { SN: '85',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          ID: 'CD27',    SVG: 'CD27',    BLK: 'CD27',  YARD: 'HHI', },
        { SN: '86',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           ID: 'CD31',    SVG: 'CD31',    BLK: 'CD31',  YARD: 'HHI', },
        { SN: '87',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           ID: 'CD34',    SVG: 'CD34',    BLK: 'CD34',  YARD: 'HHI', },
        { SN: '88',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           ID: 'CX11',    SVG: 'CX11',    BLK: 'CX11',  YARD: 'HHI', },
        { SN: '89',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           ID: 'CK11',    SVG: 'CK11',    BLK: 'CK11',  YARD: 'HHI', },
        { SN: '90',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           ID: 'CK14',    SVG: 'CK14',    BLK: 'CK14',  YARD: 'HHI', },
        { SN: '91',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck',     ID: 'SD11',    SVG: 'SD11',    BLK: 'SD11',  YARD: 'HHI', },
        { SN: '92',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck',     ID: 'SD12',    SVG: 'SD12',    BLK: 'SD12',  YARD: 'HHI', },
        { SN: '93',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck',           ID: 'SD31',    SVG: 'SD31',    BLK: 'SD31',  YARD: 'HHI', },
        { SN: '94',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck',           ID: 'SD33',    SVG: 'SD33',    BLK: 'SD33',  YARD: 'HHI', },
        { SN: '95',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck',           ID: 'SX11',    SVG: 'SX11',    BLK: 'SX11',  YARD: 'HHI', },
        { SN: '96',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Generator Building',  ID: 'GD11',    SVG: 'GD11',    BLK: 'GD11',  YARD: 'HHI', },
        { SN: '97',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Erectrical Building', ID: 'ER31',    SVG: 'ER31',    BLK: 'ER31',  YARD: 'HHI', },
        { SN: '98',  AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Flare Tower',         ID: 'NF11',    SVG: 'NF11',    BLK: 'NF11',  YARD: 'HHI', },
        { SN: '99',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 1',          ID: 'LD11',    SVG: 'LD11',    BLK: 'LD11',  YARD: 'HHI', },
        { SN: '100', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 2',          ID: 'LD21',    SVG: 'LD21',    BLK: 'LD21',  YARD: 'HHI', },
        { SN: '101', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 3',          ID: 'LD31',    SVG: 'LD31',    BLK: 'LD31',  YARD: 'HHI', },
        { SN: '102', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          ID: 'LD41',    SVG: 'LD41',    BLK: 'LD41',  YARD: 'HHI', },
        { SN: '103', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          ID: 'LHS1',    SVG: 'LHS1',    BLK: 'LHS1',  YARD: 'HHI', },
        { SN: '104', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          ID: 'LH11',    SVG: 'LH11',    BLK: 'LH11',  YARD: 'HHI', },
        { SN: '105', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          ID: 'LV51',    SVG: 'LV51',    BLK: 'LV51',  YARD: 'HHI', },
        { SN: '106', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          ID: 'LV52',    SVG: 'LV52',    BLK: 'LV52',  YARD: 'HHI', },
        { SN: '107', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          ID: 'LV53',    SVG: 'LV53',    BLK: 'LV53',  YARD: 'HHI', },
        { SN: '108', AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'MODULE',              ID: 'NORTH',   SVG: 'NORTH',   BLK: 'ND11',  YARD: 'HHI', },
        { SN: '109', AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'MODULE',              ID: 'CENTRAL', SVG: 'CENTRAL', BLK: 'CD11',  YARD: 'HHI', },
        { SN: '110', AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'MODULE',              ID: 'SOUTH',   SVG: 'SOUTH',   BLK: 'SD11',  YARD: 'HHI', },
        { SN: '111', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'MODULE',              ID: 'LQ',      SVG: 'LQ',      BLK: 'LD11',  YARD: 'HHI', },
      ],

    }

  }),
}