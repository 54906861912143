
import * as d3 from 'd3'

export default {
  methods: {

  
    draw_detail() {

      let item = this.model_Equipment
      .append('g')
      .attr('id', `CANVAS_DETAIL`)
      .attr('transform', `translate(40, 365)`)
      .attr('font-family', 'roboto')

      item
      .append('text')
      .attr('transform', `translate(0,-23)`).attr('font-size', 14).attr('fill', '#000')
      .text('Detail informations')

      this.detail.forEach((d,i)=> {
        item
        .append('text')
        .attr('id', `D_NAME_${d.code}`)
        .attr('transform', `translate(0, ${i*20})`).attr('font-size', 11).attr('fill', '#757575')
        .text(d.item + ':')

        item
        .append('text')
        .attr('id', `DETAIL_${d.code}`)
        .attr('x', this.getNodeElValue(`#D_NAME_${d.code}`,'width')+5).attr('y', i*20)
        .attr('font-size', 11).attr('fill', '#000')
        .text('') 
      })

      
    },


    update_detail(_data) {

      this.detail.forEach(d => {
        d3.select(`#DETAIL_${d.code}`).text(_data[d.code]) 
      })

    }

    

    

   

  }
}