
import * as d3 from 'd3'

export default {

  data: () => ({

  }),


  methods: {

    func_stageProgress(selection, data, stl) {

      let progress =  selection
      .append('g')
      .attr('transform', `translate(${stl.x}, ${stl.y})`)
      .attr('font-family', 'roboto')

      progress
      .append('text')
      .attr('id', `BLOCK_${data.BLK}_${stl.id}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-size', 18).attr('fill', '#000')
      .text(data.NAME)
     
      // progress
      // .append('text')
      // .attr('id', `ACTUAL_${data.BLK}_${stl.id}`)
      // .attr('x', this.getNodeElValue(`#BLOCK_${data.BLK}_${stl.id}`, 'width') + 10)
      // .attr('font-size', 10).attr('fill', '#bcbcbc')
      // .text('Actual Cuml.')

      // progress
      // .append('text')
      // .attr('x', this.getNodeElValue(`#BLOCK_${data.BLK}_${stl.id}`, 'width') + this.getNodeElValue(`#ACTUAL_${data.BLK}_${stl.id}`, 'width') + 15)
      // .attr('font-size', 12).attr('fill', '#000')
      // .text(data.ACT + '%')


      // progress
      // .append('text')
      // .attr('id', `PLAN_${data.BLK}_${stl.id}`)
      // .attr('x', 0).attr('y', 16)
      // .attr('font-size', 10).attr('fill', '#bcbcbc')
      // .text('Early Plan Cuml.')

      // progress
      // .append('text')
      // .attr('id', `PLAN_PRO_${data.BLK}_${stl.id}`)
      // .attr('x', this.getNodeElValue(`#PLAN_${data.BLK}_${stl.id}`, 'width') + 10)
      // .attr('y', 16)
      // .attr('font-size', 12).attr('fill', '#757575')
      // .text(data.PLAN + '%')

      // progress
      // .append('text')
      // .attr('id', `PLAN_PRO_${data.BLK}`)
      // .attr('x', this.getNodeElValue(`#PLAN_${data.BLK}_${stl.id}`, 'width') + this.getNodeElValue(`#PLAN_PRO_${data.BLK}_${stl.id}`, 'width') + 20)
      // .attr('y', 16)
      // .attr('font-size', 12).attr('fill', this.setDeltaColor(data.DELTA))
      // .text(data.DELTA + '%')
    },


    func_stageProgress_EL(selection, data, stl) {

      let progress =  selection
      .append('g')
      .attr('transform', `translate(${stl.x}, ${stl.y})`)
      .attr('font-family', 'roboto')

      progress
      .append('text')
      .attr('id', `BLOCK_${data.BLK}_${stl.id}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-size', 18).attr('fill', '#000')
      .text(data.NAME)
     
      progress
      .append('text')
      .attr('id', `ACTUAL_${data.BLK}_${stl.id}`)
      .attr('x', this.getNodeElValue(`#BLOCK_${data.BLK}_${stl.id}`, 'width') + 10)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Actual Cuml.')

      progress
      .append('text')
      .attr('x', this.getNodeElValue(`#BLOCK_${data.BLK}_${stl.id}`, 'width') + this.getNodeElValue(`#ACTUAL_${data.BLK}_${stl.id}`, 'width') + 15)
      .attr('font-size', 12).attr('fill', '#000')
      .text(data.ACT + '%')


      // Early
      progress
      .append('text')
      .attr('id', `EARLY${data.BLK}_${stl.id}`)
      .attr('x', 0).attr('y', 16)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Early Plan Cuml.')

      progress
      .append('text')
      .attr('id', `EP_${data.BLK}_${stl.id}`)
      .attr('x', this.getNodeElValue(`#EARLY${data.BLK}_${stl.id}`, 'width') + 10)
      .attr('y', 16)
      .attr('font-size', 12).attr('fill', '#757575')
      .text(data.EP + '%')

      progress
      .append('text')
      .attr('id', `ED_${data.BLK}`)
      .attr('x', this.getNodeElValue(`#EARLY${data.BLK}_${stl.id}`, 'width') + this.getNodeElValue(`#EP_${data.BLK}_${stl.id}`, 'width') + 20)
      .attr('y', 16)
      .attr('font-size', 12).attr('fill', this.setDeltaColor(data.ED))
      .text(data.ED.toFixed(1) + '%')

      let gap = this.getNodeElValue(`#EARLY${data.BLK}_${stl.id}`, 'width') +
                this.getNodeElValue(`#EP_${data.BLK}_${stl.id}`, 'width') + 
                this.getNodeElValue(`#ED_${data.BLK}_${stl.id}`, 'width') + 70
      // Late
      progress
      .append('text')
      .attr('id', `LATE${data.BLK}_${stl.id}`)
      .attr('x', gap).attr('y', 16)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Late Plan Cuml.')

      progress
      .append('text')
      .attr('id', `LP_${data.BLK}_${stl.id}`)
      .attr('x', gap + this.getNodeElValue(`#LATE${data.BLK}_${stl.id}`, 'width') + 10)
      .attr('y', 16)
      .attr('font-size', 12).attr('fill', '#757575')
      .text(data.LP + '%')

      progress
      .append('text')
      .attr('id', `LD_${data.BLK}`)
      .attr('x', gap + this.getNodeElValue(`#LATE${data.BLK}_${stl.id}`, 'width') + this.getNodeElValue(`#LP_${data.BLK}_${stl.id}`, 'width') + 20)
      .attr('y', 16)
      .attr('font-size', 12).attr('fill', this.setDeltaColor(data.ED))
      .text(data.LD.toFixed(1) + '%')
    },

  }
}