
import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Module(_d) { 

      this.model_Module = this.svg
      .append('g')
      .attr('id', `MODEL_MODULE`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      // Load a Legend SVG
      this.loadSvg(
        this.model_Module, {
        x: 0, y: 0,
        url : `/Visualization/Layout_Equipment/${_d.MOD}.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')
        
        d3.select(`#MASKS`).attr('opacity', 0)
        this.svgFile.filter(f=> f.MOD == _d.MOD).forEach(d=> {
          d3.select(`#MASK-${d.LEV}`).attr('cursor', 'pointer').call(this.call_Module, d)
        })
        this.draw_Module_Canvas(_d) // ----->> canvas_Overall.js
      })
    
    },


    draw_Module_Canvas(_d) {

      let btnX = this.getNodeElValue(`#SUB_TITLE`, 'width') + 50

      this.canvas_Module = this.svg
      .append('g')
      .attr('id', `CANVAS_MODULE`).attr('transform', `translate(0,0)`).attr('font-family', 'roboto')

      this.gmx_btn_Navigation(
        this.canvas_Module, 
        {type: 'home', x: 450, y: 83, scale: 0.8,}, 
        {
          remove: [this.model_Module, this.canvas_Module],
          dur: 500, 
          timeout: 1000,
          exeFunc: this.draw_Model_Overall,
          data: _d
        }
      )


      d3.select(`#TITLE_MAIN`).text(`${_d.MOD} MODULE -`)
      d3.select(`#TITLE_SUB`).attr('x', this.getNodeElValue('#TITLE_MAIN','width') + 35).text(`Overall`)

      this.bar_Summary(this.canvas_Module, this.areaSummary.find(f=> f.MOD == _d.MOD), {idx: 'OV', size: 'L', x: 90,   y: 280, bar: 250})

      this.svgFile.filter(f=> f.MOD == _d.MOD).forEach((d,i)=> {
        if (d.x == null) return
        let data = d
        if(d.LEV == 'ER1') {
          data.NAME = 'Erectrical B/D'
          data.TOTAL = this.svgFile.filter(f=> f.LEV.includes('ER')).map(m=> {return m.TOTAL}).reduce((a, b) => (a + b))
          data.PO = this.svgFile.filter(f=> f.LEV.includes('ER')).map(m=> {return m.PO}).reduce((a, b) => (a + b))
          data.FAT = this.svgFile.filter(f=> f.LEV.includes('ER')).map(m=> {return m.FAT}).reduce((a, b) => (a + b))
          data.SITE = this.svgFile.filter(f=> f.LEV.includes('ER')).map(m=> {return m.SITE}).reduce((a, b) => (a + b))
          data.INSTALL = this.svgFile.filter(f=> f.LEV.includes('ER')).map(m=> {return m.INSTALL}).reduce((a, b) => (a + b))
          data.ETA_SRD = this.svgFile.filter(f=> f.LEV.includes('ER')).map(m=> {return m.ETA_SRD}).reduce((a, b) => (a + b))
        }

        this.bar_Summary(
          this.canvas_Module, 
          data, 
          {idx: d.LEV, size: 'S', x: d.x,   y: d.y, bar: 200}
        )
      })

    },

  }
}