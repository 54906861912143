import * as d3 from 'd3'

export default {
  data: () => ({
    selectedItems: null,
    getItems: null,
  }),

  methods: {

    set_selectedItems(){  
      this.selectedItems = { 
        ...this.selectedItems,
        areaText: this.currentArea.text,
        yardText: this.currentYard.text,
        stageTitle: this.currentStage.title,
      }
    },

    draw_PDF() {
      
      this.currentArea = this.areaCodes.find(f=> f.text == this.getItems.areaText)
      this.currentYard = this.yardCodes.find(f=> f.text == this.getItems.yardText)
      this.currentStage = this.stageCodes.find(f=> f.title == this.getItems.stageTitle)
      
      if (this.currentArea.status == 'HULL') {
        d3.select(`#Legend_Stage`).transition().duration(500).attr('transform',`translate(0,25)`)
        this.legend_Yard(d3.select(`#Legend_Area`))

        d3.select(`#YARD_ALL`).attr('opacity', 0.8) 
        d3.select(`#YARD_text_ALL`).attr('font-weight', 400).attr('fill', '#333')
        d3.select(`#YARD_in_ALL`).attr('fill', '#fff')

        d3.select(`#YARD_${this.currentYard.status}`).attr('opacity', 1) 
        d3.select(`#YARD_text_${this.currentYard.status}`).attr('font-weight', 500).attr('fill', '#000')
        d3.select(`#YARD_in_${this.currentYard.status}`).attr('fill', '#757575')

        this.set_Count_Yard_Pdf(this.currentArea.status, this.currentYard.status)
      } else {
        d3.select(`#AREA_OVERALL`).attr('opacity', 0.8) 
        d3.select(`#AREA_text_OVERALL`).attr('font-weight', 400).attr('fill', '#333')
        d3.select(`#AREA_in_OVERALL`).attr('fill', '#fff')
  
        d3.select(`#AREA_${this.currentArea.status}`).attr('opacity', 1)
        d3.select(`#AREA_text_${this.currentArea.status}`).attr('font-weight', 500).attr('fill', '#000')
        d3.select(`#AREA_in_${this.currentArea.status}`).attr('fill', '#F35E90')
  
        d3.select(`#STAGE_BOX_${this.stageCodes[0].id}`).attr('opacity', 0)
        d3.select(`#STAGE_TEXT_${this.stageCodes[0].id}`).attr('fill', '#757575')
        d3.select(`#STAGE_TOT_${this.stageCodes[0].id}`).attr('fill', '#757575')
  
        d3.select(`#STAGE_BOX_${this.currentStage.id}`).attr('opacity', 1)
        d3.select(`#STAGE_TEXT_${this.currentStage.id}`).attr('fill', '#000')
        d3.select(`#STAGE_TOT_${this.currentStage.id}`).attr('fill', '#000')
  
        this.set_Count_Pdf(this.currentArea.status)
      }
      
      this.redraw_Timeline_Pdf()
      d3.select(`#DETAIL`).attr('opacity', 0)

    },
  }
}